import "./MLink.css";

import {IMInteractiveComponentProps} from "./IMInteractiveComponentProps";
import {AnchorHTMLAttributes} from "react";
import {Link, To} from "react-router-dom";

interface IMLinkProps extends IMInteractiveComponentProps<HTMLAnchorElement>, AnchorHTMLAttributes<HTMLAnchorElement> {
    to?: To;
}

export function MLink({to, ...props}: IMLinkProps) {
    if (to == null){
        return <a className="m-link" {...props}/>;
    }

    return <Link to={to} className="m-link" {...props}/>;
}