import React, {useEffect, useState} from 'react';
import "./MFirebaseLoginState.css";

import userIcoUnlogged from "../../assets/images/User.svg";
import userIcoUnloggedHover from "../../assets/images/User_LinesOrange.svg";
import userIcoLogged from "../../assets/images/User_Backgrounded.svg";

import {onAuthStateChanged} from "firebase/auth";
import {MUser} from "../../types/core/MUser";
import {IMComponentProps} from "../core/IMComponentProps";

import {auth} from '../../Firebase';

import {useNavigate} from "react-router-dom";
import {MFirebaseUser} from "../../types/firebase/MFirebaseUser";
import {SlidariDataApi} from "../../api/SlidariDataApi";

export interface IMFirebaseLoginStateProps extends IMComponentProps<HTMLElement> {
}

export function MFirebaseLoginState({style}: IMFirebaseLoginStateProps) {
    const [isHovered, setIsHovered] = useState(false);
    const [firebaseUser, setFirebaseUser] = useState<MFirebaseUser | null>();
    const [user, setUser] = useState<MUser | null>();

    const navigate = useNavigate();

    useEffect(() => {
        onAuthStateChanged(auth, (user) => {
            if (user) {
                setFirebaseUser(new MFirebaseUser(user.uid, user.displayName));
                loadUser().then();
            } else {
                setFirebaseUser(null);
                setUser(null);
            }
        });
    }, [])

    /**
     * Benutzer vom Slidari Server laden
     */
    async function loadUser() {
        if (auth.currentUser == null) {
            setUser(null);
            return;
        }

        try {
            const jwt = await auth.currentUser.getIdToken(true);
            const userResponse = await SlidariDataApi.fetchUser<MUser>(auth.currentUser.uid, jwt);
            if (userResponse.status === 200) {
                setUser(userResponse.data);
            } else {
                setUser(null);
            }
        } catch (error) {

        }
    }

    function onUserInformationClicked() {
        if (firebaseUser != null) {
            navigate("/manage-profile");
        } else {
            navigate("/login");
        }
    }

    function onUserInformationMouseEnter() {
        setIsHovered(true);
    }

    function onUserInformationMouseLeave() {
        setIsHovered(false);
    }

    function renderLogin() {
        if (firebaseUser != null) {
            return <></>;
        }

        return <>
            {!isHovered && <img src={userIcoUnlogged} alt="user"/>}
            {isHovered && <img src={userIcoUnloggedHover} alt="user"/>}
        </>
    }

    function renderUserInformation() {
        if (firebaseUser == null) {
            return <></>;
        }

        return <>
            <img src={userIcoLogged} alt="user"/>
            {user?.displayName && <span>{user?.displayName}</span>}
        </>
    }

    function getClassName() {
        if (firebaseUser == null) {
            return "m-firebase-login-state firebaseUser-not-logged";
        }

        return "m-firebase-login-state";
    }

    return <div className={getClassName()} style={style}>
        <div id="userInformation"
             onClick={onUserInformationClicked}
             onMouseEnter={onUserInformationMouseEnter}
             onMouseLeave={onUserInformationMouseLeave}>
            {renderLogin()}
            {renderUserInformation()}
        </div>
    </div>
}