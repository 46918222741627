import "./MFlexPanel.css";

import {IMComponentProps} from "./IMComponentProps";

interface IMHintProps extends IMComponentProps<HTMLElement> {
}

export function MFlexPanel({children}: IMHintProps) {
    return <div className="m-flex-panel">
        {children}
    </div>;
}