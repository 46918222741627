import "./MPanel.css";

import {MTitle} from "./MTitle";

import {IMComponentProps} from "./IMComponentProps";
import {IMTitledComponentProps} from "./IMTitledComponentProps";

interface IMPanelProps extends IMComponentProps<HTMLElement>, IMTitledComponentProps {
    /**
     * Setzen setzt Padding für den Inhalt auf 0px
     */
    noPadding?: boolean;
}

export function MPanel({title, iconScr, iconAlt, noPadding, className, ...props}: IMPanelProps) {
    function getClassName() {
        let cName = "m-panel";

        if (className != null && className != "") {
            cName += " " + className;
        }

        if (noPadding) {
            cName += " no-padding";
        }

        return cName;
    }

    function renderTitle() {
        if (title == null || title == "") {
            return <></>;
        }

        if (iconScr == null || iconScr == "") {
            return <></>;
        }

        return <>
            <MTitle title={title}
                    iconScr={iconScr}
                    iconAlt={iconAlt} style={{marginBottom: "0"}}/>
            <hr/>
        </>
    }

    return <div className={getClassName()}
                {...props}>
        {renderTitle()}
        <div>
            {props.children}
        </div>
    </div>;
}