import {useEffect, useState} from "react";

import {MHint} from "../core/MHint";

import {IMComponentProps} from "../core/IMComponentProps";
import {MApiAdapter} from "../../api/MApiAdapter";
import {UserRoleMap} from "../../types/UserRoleMap";

import {auth} from "../../Firebase";

export class RoleRequirement {
    constructor(role: string | null) {
        this.role = role;
    }

    role: string | null;
}

interface IMAuthorizedContentProps extends IMComponentProps<HTMLElement> {
    showUnauthorizedHint?: boolean;
    roleRequirements?: RoleRequirement[];
}

export function MAuthorizedContent({showUnauthorizedHint, roleRequirements, children}: IMAuthorizedContentProps) {
    const [isUserActive, setIsUserActive] = useState(false);
    const [roles, setRoles] = useState<string[]>([]);

    if (showUnauthorizedHint == null) {
        showUnauthorizedHint = false;
    }

    if (roleRequirements == null) {
        roleRequirements = [];
    }

    useEffect(() => {
        auth.onAuthStateChanged(async function (user) {
            if (user) {
                setIsUserActive(true);

                // Wenn Rollen zur Freischaltung des Inhalts notwendig sind, dann Rollen des Benutzers laden
                if (roleRequirements != null && roleRequirements.length > 0) {
                    const jwt: string = await user.getIdToken();
                    const roleResponse = await MApiAdapter.fetchFromApi<UserRoleMap>("http://localhost:5199/api.slidari/v1/user-role-maps/" + user.uid, jwt)
                    if (roleResponse.status === 200 && roleResponse.data != null) {
                        setRoles(roleResponse.data?.userRoles);
                    } else {
                        setRoles([]);
                    }
                } else {
                    setRoles([]);
                }
            } else {
                setIsUserActive(false);
                setRoles([]);
            }
        });
    }, []);

    function renderUnauthorizedHint() {
        if (!showUnauthorizedHint || isUserActive) {
            return <></>;
        }

        return <MHint>Dazu fehlen dir die Berechtigungen!</MHint>
    }

    function renderContent() {
        if (!isUserActive) {
            return <></>;
        }

        if (roleRequirements == null || roleRequirements.length == 0) {
            return children;
        }

        for (let i = 0; i < roleRequirements.length; i++) {
            const requirement = roleRequirements[i];
            if (requirement == null || requirement.role == null) {
                continue;
            }

            if (!roles.includes(requirement.role)) {
                return <></>;
            }
        }

        return children;
    }

    return <>
        {renderContent()}
        {renderUnauthorizedHint()}
    </>;
}