import {MBasePage} from "./core/MBasePage";

import {MFirebaseUserManagementPanel} from "../components/firebase/MFirebaseUserManagementPanel";
import {MAuthorizedContent, RoleRequirement} from "../components/firebase/MAuthorizedContent";
import {AdminPanel} from "../components/AdminPanel";
import {MFlexPanel} from "../components/core/MFlexPanel";
import {SlidariRushManagerPanel} from "../components/SlidariRushManagerPanel";

export function ManageProfilePage() {
    return <MBasePage showHeader={true}>
        <MFlexPanel>
            <MFirebaseUserManagementPanel/>
            <MAuthorizedContent roleRequirements={[new RoleRequirement("Admin")]}>
                <AdminPanel/>
            </MAuthorizedContent>
            <MAuthorizedContent>
                <SlidariRushManagerPanel gameTitle={"Slidari Rush"}/>
            </MAuthorizedContent>
        </MFlexPanel>
    </MBasePage>;
}