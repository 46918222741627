export class MUser {
    constructor(userId: string, displayName: string | null = null) {
        this.userId = userId;
        this.displayName = displayName;
        this.isAccountPrivate = false;
        this.userRolesChain = "";
        this.isAccountPrivate = false;
    }

    userId?: string;
    displayName?: string | null;
    isAccountPrivate?: boolean;
    userRolesChain?: string;
    isPrivatePolicyAccepted?: boolean;
}