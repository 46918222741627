import "./MBasePage.css";
import {HTMLAttributes, useEffect} from "react";

import {MHeader} from "../../components/core/MHeader";
import {MFooter} from "../../components/core/MFooter";
import {MFirebaseLoginState} from "../../components/firebase/MFirebaseLoginState";

import {IMInteractiveComponentProps} from "../../components/core/IMInteractiveComponentProps";

interface IMPageProps extends IMInteractiveComponentProps<HTMLElement>, HTMLAttributes<HTMLElement> {
    /**
     * Kopfzeile mit Navigation anzeigen
     */
    showHeader?: boolean;
    /**
     * Seitentitel, der auch in der Kopfzeile angezeigt wird
     */
    pageTitle?: string;
}

export function MBasePage({showHeader, pageTitle, ...props}: IMPageProps) {
    if (showHeader == null) {
        showHeader = true;
    }

    useEffect(() => {
        if (pageTitle == null) {
            document.title = "";
            return;
        }

        document.title = pageTitle;
    }, []);

    const menuItems = [
        {title: 'Home', url: '/'},
        {title: 'Spiele', url: '/games'},
        {title: 'Über uns', url: '/about'}
    ];

    function getClassName() {
        let cName = "m-page-content";
        if (!showHeader) {
            cName += " without-header";
        }

        if (props.className != null && props.className != "") {
            cName += " " + props.className;
        }

        return cName;
    }

    return <div style={props.style} className={"m-page-content-wrapper"}>
        {showHeader &&
            <MHeader title={pageTitle} userStateSection={<MFirebaseLoginState/>} navigationItems={menuItems}/>}
        <main className={getClassName()}>
            {props.children}
        </main>
        <MFooter/>
    </div>;
}