import React, {useEffect, useState} from "react";
import "./MNavigation.css";

import {NavLink} from "react-router-dom";
import {MHamburger} from "./MHamburger";
import {MFooter} from "./MFooter";

import {IMComponentProps} from "./IMComponentProps";

interface IMNavigationProps extends IMComponentProps<HTMLElement> {
    items?: MNavigationItem[];
}

export class MNavigationItem {
    title?: string;
    url?: any;
}

export function MNavigation({items}: IMNavigationProps) {
    const [isMobileNavigationVisible, setIsMobileNavigationVisible] = useState<boolean>(false);
    const [isPageScrolled, setIsPageScrolled] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 20) {
                setIsPageScrolled(true);
            } else {
                setIsPageScrolled(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    if (items == null) {
        items = [];
    }

    function onToggleMobileNavigationClicked() {
        setIsMobileNavigationVisible(!isMobileNavigationVisible);
    }

    return <>
        {!isMobileNavigationVisible &&
            <MHamburger id="btnToggleNavigation" onClick={onToggleMobileNavigationClicked}/>}
        <nav>
            <div className={!isPageScrolled ? "m-navigation" : "m-navigation page-scrolled"}>
                <ul>
                    {items.map((item, index) => (
                        <NavLink to={item.url}>
                            <li key={index}>
                                {item.title}
                            </li>
                        </NavLink>
                    ))}
                </ul>
            </div>
            <div className={!isMobileNavigationVisible ? "m-navigation mobile" : "m-navigation mobile shown"}>
                <div className="header-text">
                    <h1>Menü</h1>
                </div>
                <ul>
                    {items.map((item, index) => (
                        <NavLink to={item.url}>
                            <li key={index}>
                                {item.title}
                            </li>
                        </NavLink>
                    ))}
                    <a>
                        <li onClick={() => {
                            setIsMobileNavigationVisible(false);
                        }}><span>Zurück</span></li>
                    </a>
                </ul>
                <div style={{bottom: "0", position: "fixed", width: "100%"}}>
                    <MFooter/>
                </div>
            </div>
        </nav>
    </>;
}