import {MApiResponse} from "./MApiResponse";
import {SlidariDataApi} from "./SlidariDataApi";

export class SlidariRushDataApi {
    static async fetchSaveGame<T>(ownerId: string, jwt: string = ""): Promise<MApiResponse<T>> {
        return await SlidariDataApi.fetchGameData(ownerId, "sr_sg_v1", jwt);
    }

    static async fetchAvatarPhoto<T>(ownerId: string, jwt: string = ""): Promise<MApiResponse<T>> {
        return await SlidariDataApi.fetchGameData(ownerId, "sr_ap_v1", jwt);
    }
}

