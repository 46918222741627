import React from "react";
import {IMInputAttributes} from "./MInputUtilities";

export class MValidationUtilities {
    public static validateInput(value: string, setValidationErrors: React.Dispatch<React.SetStateAction<string | undefined>>, validators?: MInputValidatorWithMeta[]) {
        if (validators == null) {
            return true;
        }

        for (let validator of validators) {
            const validationError = validator(value);
            if (validationError) {
                setValidationErrors(validationError);
                return false;
            }
        }

        setValidationErrors(undefined);
        return true;
    }
}

export type MInputValidator = (value: string | boolean) => string | undefined;
export type MInputValidatorWithMeta = MInputValidator & { type?: string; params?: any };

export interface IMInputBaseProps {
    /**
     * Name des HTML Elementes
     */
    name: string;
    /**
     * Labeltext
     */
    labelContent?: any;
    /**
     * Validierungsvorgaben
     */
    validators?: MInputValidatorWithMeta[];
}

export const MInputValidationRules = {
    required: Object.assign(
        (value: string) => value.trim() ? undefined : 'Dieses Feld darf nicht leer sein!',
        {type: 'required'}
    ) as MInputValidatorWithMeta,
    minLength: (min: number) => Object.assign(
        (value: string) => value.length >= min ? undefined : `Minimale Zeichenlänge ${min} unterschritten!`,
        {type: 'minLength', params: min}
    ) as MInputValidatorWithMeta,
    maxLength: (max: number) => Object.assign(
        (value: string) => value.length <= max ? undefined : `Maximale Zeichenlänge ${max} überschritten!`,
        {type: 'maxLength', params: max}
    ) as MInputValidatorWithMeta,
    min: (min: number) => Object.assign(
        (value: string) => Number(value) >= min ? undefined : `Der Wert muss größer kleiner als ${min} sein!`,
        {type: 'min', params: min}
    ) as MInputValidatorWithMeta,
    max: (max: number) => Object.assign(
        (value: string) => Number(value) <= max ? undefined : `Der Wert muss größer als ${max} sein!`,
        {type: 'max', params: max}
    ) as MInputValidatorWithMeta,
    email: Object.assign(
        (value: string) => /\S+@\S+\.\S+/.test(value) ? undefined : 'E-Mail-Adresse mit unzulässigem Format!',
        {type: 'pattern', params: '\\S+@\\S+\\.\\S+'}
    ) as MInputValidatorWithMeta
};

export function extractInputAttributesFromValidators(validators: MInputValidatorWithMeta[]): IMInputAttributes {
    const attributes: IMInputAttributes = {};

    validators.forEach(validator => {
        if (validator.type === 'pattern') {
            attributes.pattern = validator.params;
        }

        if (validator.type === 'minLength') {
            attributes.minLength = validator.params;
        }

        if (validator.type === 'maxLength') {
            attributes.maxLength = validator.params;
        }

        if (validator.type === 'min') {
            attributes.min = validator.params;
        }

        if (validator.type === 'max') {
            attributes.max = validator.params;
        }
    });

    return attributes;
}