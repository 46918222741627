import {MBasePage} from "./core/MBasePage";

import {MFormattedTextContent} from "../components/core/MFormattedTextContent";
import {SmoothFadedContent} from "../components/SmoothFadedContent";

export function PrivacyPolicyPage() {
    const lastUpdate = "24.08.2024"

    return <MBasePage className="privacy-policy-page" pageTitle="Datenschutz">
        <SmoothFadedContent>
            <MFormattedTextContent style={{margin: "auto", maxWidth: "800px"}}>
                <h1>Datenschutzerklärung</h1>
                <p>Wir nehmen den Schutz Ihrer persönlichen Daten sehr ernst und behandeln Ihre personenbezogenen Daten
                    vertraulich und entsprechend der gesetzlichen Datenschutzvorschriften sowie dieser
                    Datenschutzerklärung.
                    Im Folgenden informieren wir Sie über die Erhebung, Verarbeitung und Nutzung personenbezogener Daten
                    bei
                    der Nutzung unserer Webplattform und der damit verbundenen mobilen Spiele.</p>

                <h2>1. Verantwortliche Stelle</h2>
                <p>Verantwortlich für die Datenverarbeitung auf dieser Webplattform ist:</p>
                <p>Joscha Boldt<br/>
                    Bleichstr. 92<br/>
                    67655 Kaiserslautern<br/>
                    info@slidari-world.com</p>

                <h2>2. Hosting</h2>
                <p>Unsere Webseite wird auf einem IONOS Server gehostet. Der Serverstandort befindet sich in
                    Deutschland.
                    IONOS stellt die technische Infrastruktur für den Betrieb dieser Webseite bereit und ermöglicht den
                    Zugriff auf unsere Online-Dienste.
                    Im Rahmen des Hostings werden Verbindungsdaten und andere technische Daten
                    erhoben, die notwendig sind, um die Webseite sicher und zuverlässig betreiben zu können. Dazu
                    gehören:</p>
                <ul>
                    <li>IP-Adressen der Besucher</li>
                    <li>Datum und Uhrzeit des Zugriffs</li>
                    <li>Informationen über den verwendeten Browsertyp und das Betriebssystem</li>
                    <li>Referrer-URL (die zuvor besuchte Seite)</li>
                </ul>
                <p>Diese Daten werden ausschließlich zum Zwecke der Gewährleistung eines reibungslosen Betriebs der
                    Webseite
                    und zur Verbesserung unseres Angebots verarbeitet. Eine Zusammenführung dieser Daten mit anderen
                    Datenquellen wird nicht vorgenommen.</p>
                <p>Die in diesem Zusammenhang erhobenen Verbindungsdaten (Logs) werden für maximal 7 Tage gespeichert
                    und
                    danach automatisch gelöscht, es sei denn, es bestehen gesetzliche Aufbewahrungspflichten oder die
                    Daten
                    werden zur Aufklärung von Sicherheitsvorfällen weiterhin benötigt.</p>

                <h2>3. Erhebung und Verarbeitung personenbezogener Daten</h2>

                <h3>3.1 Benutzerregistrierung</h3>
                <p>Bei der Registrierung auf unserer Webplattform oder in den mobilen Spielen mit Onlinebindung erheben
                    wir
                    folgende personenbezogene Daten:</p>
                <ul>
                    <li><strong>E-Mail-Adresse:</strong> Zur Authentifizierung und Kommunikation.</li>
                    <li><strong>Benutzeralias:</strong> Ein von Ihnen gewählter Aliasname, der in unserer Plattform und
                        in
                        den mobilen Spielen verwendet wird.
                    </li>
                </ul>
                <p>Diese Daten werden benötigt, um Ihnen die Nutzung unserer Online-Dienste zu ermöglichen.</p>

                <h3>3.2 Benutzerverwaltung</h3>
                <p>Die Verwaltung Ihrer Benutzerinformationen erfolgt über Google Firebase. Die folgenden
                    Zusatzinformationen werden nicht bei Google Firebase, sondern in einer separaten Datenbank
                    gespeichert:</p>
                <ul>
                    <li><strong>Benutzeralias</strong></li>
                    <li><strong>Datum der letzten Änderung des Accounts</strong></li>
                    <li><strong>Erstelldatum des Accounts</strong></li>
                    <li><strong>Zielllöschdatum, wenn der Account als gelöscht markiert wurde</strong></li>
                </ul>

                <h3>3.3 Verwendung in mobilen Spielen</h3>
                <p>Der Login der Webplattform und die mobilen Spiele mit Onlinebindung verwenden dieselben
                    Anmeldeinformationen. In diesen Spielen werden Spielstände automatisch gespeichert, die den
                    Fortschritt
                    im Spiel enthalten, wie beispielsweise freigeschaltete Level, verdiente Medaillen und andere
                    Errungenschaften. Aus diesen Daten werden die Bestenlisten erstellt, in denen die besten Spieler
                    angezeigt werden.</p>
                <p>Die Nutzung der Online-Funktion, einschließlich der Speicherung von Spielständen und der Teilnahme an
                    Highscore-Bestenlisten, ist freiwillig.</p>

                <h2>4. Nutzung Ihrer Daten</h2>

                <h3>4.1 Highscore-Bestenlisten</h3>
                <p>Wenn Sie sich entscheiden, die Online-Funktion zu nutzen, werden Ihre Spielstände zur Berechnung von
                    Highscores verwendet. Diese Highscores werden in Bestenlisten veröffentlicht, die für andere Spieler
                    und
                    öffentlich auf unserer Webplattform sichtbar sind.
                    Dabei sind lediglich die folgenden personenbezogenen Daten sichtbar:</p>
                <ul>
                    <li><strong>Benutzeralias</strong></li>
                    <li><strong>Der jeweils erreichten Highscore Wert</strong></li>
                </ul>

                <h3>4.2 Private Konten</h3>
                <p>Sie haben die Möglichkeit, Ihren Account als "privat" zu kennzeichnen. In diesem Fall:</p>
                <ul>
                    <li><strong>Teilnahme an Highscore-Bestenlisten:</strong> Ihr Benutzeralias und Ihre Highscores
                        werden
                        nicht
                        in den öffentlichen Bestenlisten angezeigt.
                    </li>
                </ul>

                <h3>4.3 Kontaktformular</h3>
                <p>Auf unserer Webplattform steht Ihnen ein Kontaktformular zur Verfügung, über das Sie Supportanfragen
                    und
                    andere Anliegen an uns richten können. Hierbei erheben wir folgende Daten:</p>
                <ul>
                    <li><strong>E-Mail-Adresse</strong></li>
                    <li><strong>Name (optional)</strong></li>
                    <li><strong>Typ des Anliegen</strong></li>
                    <li><strong>Beschreibung</strong></li>
                </ul>
                <p>Diese Daten werden ausschließlich zur Bearbeitung Ihrer Anfrage verwendet und nicht an Dritte
                    weitergegeben.
                    Ihre Angaben werden so lange gespeichert, wie es für die Bearbeitung Ihrer Anfrage erforderlich
                    ist.</p>

                <h2>5. Verwendung von Cookies und Tracking-Technologien</h2>
                <p>Unsere Webseite verwendet Cookies und ähnliche Tracking-Technologien, um die Nutzererfahrung zu
                    verbessern und bestimmte Funktionen bereitzustellen. Cookies sind kleine Textdateien, die auf Ihrem
                    Gerät gespeichert werden und die es uns ermöglichen, Ihren Browser zu erkennen und Ihre Präferenzen
                    zu
                    speichern.</p>
                <ul>
                    <li><strong>Notwendige Cookies:</strong> Diese Cookies sind für das Funktionieren der Webseite
                        erforderlich und können nicht deaktiviert werden.
                    </li>
                    <li><strong>Analytische Cookies:</strong> Diese Cookies helfen uns, die Nutzung der Webseite zu
                        analysieren und zu verbessern. Sie sammeln anonymisierte Daten über das Nutzerverhalten.
                    </li>
                </ul>
                <p>Sie können die Verwendung von Cookies in den Einstellungen Ihres Browsers verwalten und Cookies
                    blockieren. Beachten Sie jedoch, dass dies die Funktionalität der Webseite beeinträchtigen kann.</p>

                <h2>6. Datenlöschung</h2>

                <h3>6.1 Löschung des Accounts</h3>
                <p>Sie haben jederzeit das Recht, Ihren Account zu löschen. Die Löschung kann entweder über die
                    Webplattform
                    oder direkt in den mobilen Spielen mit Onlinebindung erfolgen. In diesem Fall werden:</p>
                <ul>
                    <li>Ihre personenbezogenen Daten (E-Mail, Passwort) bei Google Firebase gelöscht.</li>
                    <li>Ihre personenbezogenen Daten (Benutzeralias) aus unserer Datenbank gelöscht.</li>
                    <li>Alle gespeicherten Spielstände und damit verbundene Daten werden zur Löschung markiert und nach
                        einer Frist von 10 Tagen endgültig gelöscht.
                    </li>
                    <li>Ihre Highscores werden aus sämtlichen Bestenlisten entfernt.</li>
                </ul>
                <p>Die 10-tägige Aufbewahrungsfrist dient Sicherheitszwecken, um versehentliche Löschungen rückgängig
                    machen
                    zu können. Nach Ablauf dieser Frist ist eine Wiederherstellung der Daten nicht mehr möglich.</p>

                <h2>7. Datenspeicherung und Sicherheit</h2>
                <p>Ihre Daten werden auf unterschiedlichen Systemen gespeichert, um die verschiedenen Funktionen unserer
                    Webplattform und mobilen Spiele zu ermöglichen:</p>
                <ul>
                    <li><strong>Google Firebase:</strong> Die Verwaltung Ihrer Anmeldedaten (E-Mail-Adresse und
                        Passwörter) erfolgt über Google Firebase. Firebase speichert diese Daten in Rechenzentren in der
                        Europäischen Union, die den Anforderungen der DSGVO entsprechen.
                    </li>
                    <li><strong>IONOS:</strong> Zusätzliche Benutzerdaten wie Benutzeralias, das Erstelldatum des
                        Accounts und die Spielstände der Spiele werden auf einem von IONOS Server in einer Datenbank
                        gespeichert. E-Mail-Adresse und Passwörter sind auf diesem Server nicht gespeichert.
                        Dieser Server befindet sich in Deutschland und unterliegt somit den deutschen und
                        europäischen Datenschutzgesetzen.
                    </li>
                </ul>
                <p>Wir haben technische und organisatorische Maßnahmen getroffen, um Ihre Daten vor unbefugtem Zugriff,
                    Verlust oder Zerstörung zu schützen. Dazu gehören unter anderem verschlüsselte Verbindungen
                    (SSL/TLS)
                    sowie regelmäßige Sicherheitsüberprüfungen unserer Systeme.</p>

                <h2>8. Ihre Rechte</h2>
                <p>Sie haben das Recht:</p>
                <ul>
                    <li><strong>Auskunft</strong> über die bei uns gespeicherten Daten zu erhalten,</li>
                    <li><strong>Berichtigung</strong> unrichtiger Daten zu verlangen,</li>
                    <li><strong>Löschung</strong> Ihrer Daten zu verlangen, sofern diese nicht mehr zur
                        Vertragserfüllung
                        erforderlich sind,
                    </li>
                    <li><strong>Einschränkung der Verarbeitung</strong> Ihrer Daten zu verlangen,</li>
                    <li><strong>Widerspruch</strong> gegen die Datenverarbeitung einzulegen.</li>
                </ul>
                <p>Zur Ausübung Ihrer Rechte können Sie uns jederzeit unter den oben angegebenen Kontaktdaten erreichen.
                    Alternativ kann auch das Kontaktformular unter nachfolgendem Link verwendet werden:</p>

                <a href={"/contact"}>Kontaktformular</a>

                <h2>9. Änderungen der Datenschutzerklärung</h2>
                <p>Wir behalten uns das Recht vor, diese Datenschutzerklärung bei Bedarf anzupassen. Die jeweils
                    aktuelle
                    Version der Datenschutzerklärung kann jederzeit auf unserer Webplattform eingesehen werden.</p><br/>

                <p><strong>Datum der letzten Aktualisierung:</strong> {lastUpdate}</p>
            </MFormattedTextContent>
        </SmoothFadedContent>
    </MBasePage>;
}
