import {MBasePage} from "./core/MBasePage";
import "./HomePage.css";

import {MHint} from "../components/core/MHint";
import {SmoothFadedContent} from "../components/SmoothFadedContent";

export function HomePage() {
    return <MBasePage pageTitle="Home">
        <SmoothFadedContent>
            <h1>Slidari World</h1>
            <MHint title={"Content coming soon ..."} style={{marginTop: "12px"}}/>
        </SmoothFadedContent>
    </MBasePage>;
}
