import {MApiResponse} from "./MApiResponse";
import {MApiAdapter} from "./MApiAdapter";

import config from '../config';

export class SlidariEmailApi {
    static async postMail<T>(user: any, jwt: string = ""): Promise<MApiResponse<T>> {
        let url = this.getUrl() + "email";
        return await MApiAdapter.postToApi(url, jwt, user);
    }

    static getUrl() {
        if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
            return config.webMailerApi.develop;
        } else {
            return config.webMailerApi.production;
        }
    }
}

