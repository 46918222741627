import React, {useEffect, useRef, useState} from 'react';
import "./MScrollReveal.css";
import {IMComponentProps} from "./IMComponentProps";

interface IMScrollRevealProps extends IMComponentProps<HTMLElement> {
    threshold?: number;
    offset?: number;
}

function MScrollReveal({threshold, offset, ...props}: IMScrollRevealProps) {
    const [isVisible, setIsVisible] = useState(false);
    const contentRef = useRef(null);

    if (threshold == null) {
        threshold = 0.1;
    }

    if (offset == null){
        offset = 0;
    }

    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        setIsVisible(true);
                    } else {
                        setIsVisible(false);
                    }
                });
            },
            {
                threshold,
                rootMargin: '0px 0px ' + offset + 'px 0px'
            }
        );

        if (contentRef.current) {
            observer.observe(contentRef.current);
        }

        return () => {
            if (observer && contentRef.current) {
                observer.unobserve(contentRef.current);
            }
        };
    }, []);

    return <div className="m-scroll-reveal" {...props}>
        <div ref={contentRef}
             className={`hidden-content ${isVisible ? 'visible' : ''}`}>
            {props.children}
        </div>
    </div>;
}

export default MScrollReveal;
