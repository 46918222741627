import "./MButton.css";

import {IMInteractiveComponentProps} from "./IMInteractiveComponentProps";
import {ButtonHTMLAttributes, useEffect, useState} from "react";
import {MHint} from "./MHint";

interface IMJsonImageProps extends IMInteractiveComponentProps<HTMLElement>, ButtonHTMLAttributes<HTMLElement> {
    jsonString?: string | null;
}

export function MJsonImage({jsonString, ...props}: IMJsonImageProps) {
    const [imageSrc, setImageSrc] = useState<any>(null);
    const {style, ...restProps} = props;

    useEffect(() => {
        if (jsonString == null) {
            setImageSrc(null);
            return;
        }

        try {
            const jsonObject = JSON.parse(jsonString);
            const base64ImageWithPrefix = jsonObject.photoJson;
            const base64Image = base64ImageWithPrefix.replace(/^data:image\/[a-zA-Z]+;base64,/, '');
            setImageSrc(`data:image/jpg;base64,${base64Image}`);
        } catch (error) {
            setImageSrc(null);
        }
    }, [jsonString]);

    const combinedStyle = {
        maxWidth: '100%',
        ...style,
    };

    return imageSrc != null ? <img src={imageSrc}
                                   {...restProps}
                                   alt="JsonImage"
                                   style={combinedStyle}/> : <></>;
}