import React, {useState, createContext, useContext, ReactNode} from 'react';
import "./MPushMessage.css";

import PushMessage from './MPushMessage';
import {MPushMessageContainer} from "./MPushMessageContainer";

import {MStatusStyle} from "./MStatusStyle";

interface IPushMessageContextProps {
    push: (title: string, message?: string, statusStyle?: MStatusStyle, lifeTime?: number) => void;
}

const MPushMessageContext = createContext<IPushMessageContextProps | undefined>(undefined);

export const usePushMessage = (): IPushMessageContextProps => {
    const context = useContext(MPushMessageContext);
    if (!context) {
        throw new Error('usePushMessage must be used within a MPushMessageProvider element!');
    }

    return context;
};

interface IMPushMessageProviderProps {
    children: ReactNode;
}

interface IMPushMessage {
    id: number;
    title: string;
    message?: string | null;
    statusStyle?: MStatusStyle | null;
    lifeTime?: number | null;
}

export const MPushMessageProvider = ({children}: IMPushMessageProviderProps) => {
    const [pushMessages, setPushMessages] = useState<IMPushMessage[]>([]);

    const addPushMessage = (title: string, message: string | undefined = undefined, statusStyle: MStatusStyle | undefined = undefined, lifeTime: number | undefined = undefined) => {
        const id = Date.now();
        setPushMessages([...pushMessages, {id, title, message, statusStyle}]);

        if (lifeTime != null && lifeTime > 0) {
            setTimeout(() => removePushMessage(id), lifeTime);
        }
    };

    const removePushMessage = (id: number) => {
        setPushMessages(messages => messages.filter(message => message.id !== id));
    };

    return <MPushMessageContext.Provider value={{push: addPushMessage}}>
        {children}
        <MPushMessageContainer>
            {pushMessages.map(pm => (
                <PushMessage key={pm.id}
                             title={pm.title}
                             message={pm.message}
                             statusStyle={pm.statusStyle}
                             onClose={() => removePushMessage(pm.id)}/>
            ))}
        </MPushMessageContainer>
    </MPushMessageContext.Provider>;
};