import {motion} from "framer-motion";

import {IMInteractiveComponentProps} from "./core/IMInteractiveComponentProps";
import {HTMLAttributes} from "react";

interface IPageContentFaderProps extends IMInteractiveComponentProps<HTMLElement>, HTMLAttributes<HTMLElement> {
}

export function SmoothFadedContent({children}: IPageContentFaderProps) {
    if (children == null) {
        return <></>;
    }

    return <motion.div initial="hidden"
                       whileInView="visible"
                       viewport={{once: true}}
                       transition={{duration: 0.3}}
                       variants={{
                           visible: {opacity: 1},
                           hidden: {opacity: 0}
                       }}>
        {children}
    </motion.div>;
}
