import {ChangeEvent, ChangeEventHandler, TextareaHTMLAttributes, useEffect, useState} from "react";
import "./MInputBase.css";
import "./MTextArea.css";

import {
    extractInputAttributesFromValidators,
    IMInputBaseProps,
    MInputValidatorWithMeta,
    MValidationUtilities
} from "./MValidationUtilities";
import {MParameterHint} from "./MParameterHint";

export interface IMTextAreaProps extends TextareaHTMLAttributes<HTMLTextAreaElement>, IMInputBaseProps {
    /**
     * On Change Callback
     */
    onChange?: ChangeEventHandler<HTMLTextAreaElement> | undefined;
    validators?: MInputValidatorWithMeta[];
    name: string;
    labelContent?: any;
}

export function MTextArea({validators, value, onChange, name, labelContent, ...props}: IMTextAreaProps) {
    const [validationError, setValidationValidationError] = useState<string | undefined>(undefined);

    useEffect(() => {
        MValidationUtilities.validateInput(String(value), setValidationValidationError, validators);
    }, []);

    const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
        const value = e.target.value;
        if (onChange != null) {
            onChange(e);
        }

        MValidationUtilities.validateInput(String(value), setValidationValidationError, validators);
    };

    const htmlAttributes = extractInputAttributesFromValidators(validators == null ? [] : validators);
    const combinedClassName = `m-input-base ${props.className ?? ''}`;

    return <div className="m-text-area">
        {labelContent != null && <label>{labelContent}</label>}
        <div>
            <textarea name={name}
                      className={combinedClassName}
                      {...props}
                      {...htmlAttributes}
                      value={value}
                      onChange={handleChange}/>
        </div>
        <MParameterHint>{validationError}</MParameterHint>
    </div>;
}