import "./MButton.css";

import {IMInteractiveComponentProps} from "./IMInteractiveComponentProps";
import {ButtonHTMLAttributes} from "react";

interface IMButtonProps extends IMInteractiveComponentProps<HTMLButtonElement>, ButtonHTMLAttributes<HTMLButtonElement> {
    transparentStyle?: boolean;
}

export function MButton({transparentStyle, ...props}: IMButtonProps) {
    return <button className={transparentStyle ? "m-button transparent" : "m-button"} {...props}/>;
}