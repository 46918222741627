import "./MButtonBar.css";

import {IMComponentProps} from "./IMComponentProps";

interface IMButtonBarProps extends IMComponentProps<HTMLElement> {
}

export function MButtonBar({children, style}: IMButtonBarProps) {
    return <div className="m-button-bar" style={style}>
        {children}
    </div>;
}