import {MBasePage} from "./core/MBasePage";
import {MInputForm} from "../components/core/MInputForm";
import {MParameter} from "../components/core/MParameter";
import {MInput} from "../components/core/MInput";
import {MFormattedTextContent} from "../components/core/MFormattedTextContent";
import {MSelect} from "../components/core/MSelect";
import {useState} from "react";
import {MTextArea} from "../components/core/MTextArea";
import {usePushMessage} from "../components/core/MPushMessageProvider";
import {MHint} from "../components/core/MHint";
import MCaptcha from "../components/core/MCaptcha";
import {MButton} from "../components/core/MButton";
import {MParameterHint} from "../components/core/MParameterHint";
import {ServiceAvailableChecker} from "../components/ServiceAvailableChecker";
import {SmoothFadedContent} from "../components/SmoothFadedContent";

import {MInputValidationRules} from "../components/core/MValidationUtilities";

import {MStatusStyle} from "../components/core/MStatusStyle";
import {SlidariEmailApi} from "../api/SlidariEmailApi";
import {MContactData} from "../types/MContactData";

export function ContactPage() {
    const [email, setEmail] = useState<string>();
    const [name, setName] = useState<string>();
    const [kindOfRequest, setKindOfRequest] = useState<number>();
    const [productId, setProductId] = useState<number>();
    const [productVersion, setProductVersion] = useState<string>();
    const [text, setText] = useState<string>();
    const [requestSent, setRequestSent] = useState<boolean>(false);
    const [captchaExpected, setCaptchaExpected] = useState<boolean>(false);

    const pushMessage = usePushMessage();

    async function sendRequestClicked() {
        const contactData = new MContactData();
        contactData.senderMail = email;
        contactData.senderName = name;
        contactData.kindOfRequest = kindOfRequest;
        contactData.productId = productId;
        contactData.productVersion = productVersion;
        contactData.text = text;

        const response = await SlidariEmailApi.postMail(contactData);
        if (response.status === 200) {
            setRequestSent(true);
            pushMessage.push("Anfrage versendet!", "", MStatusStyle.Nice, 3000);
        } else {
            pushMessage.push("Anfrage nicht versendet!", "Beim versenden deiner Anfrage ist ein Problem aufgetreten!", MStatusStyle.Error);
        }
    }

    /**
     * Prüft, ob die Eingabe des Kontaktformulars gültig ist.
     */
    function isInputValid() {
        return email != null && email != "" &&
            kindOfRequest != null && kindOfRequest >= 0 &&
            text != null && text != "";
    }

    async function captchaVerified(status: boolean) {
        if (captchaExpected && status && isInputValid()) {
            setCaptchaExpected(false);
            await sendRequestClicked();
        }
    }

    function onSendRequestClicked() {
        if (isInputValid()) {
            setCaptchaExpected(true);
        }
    }

    function renderInputForm() {
        if (requestSent || captchaExpected) {
            return <></>;
        }

        return <>
            <MInputForm style={{marginTop: "20px"}}>
                <MParameter>
                    <MInput name="mailAddress"
                            type="text"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            labelContent="E-Mail Adresse"
                            placeholder={"E-Mail Adresse ..."}
                            validators={[MInputValidationRules.email]}/>
                </MParameter>
                <MParameter>
                    <MInput name="name"
                            type="text"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            labelContent="Name (optional)"
                            placeholder={"Name ..."}
                            validators={[MInputValidationRules.maxLength(64)]}/>
                </MParameter>
                <MParameter>
                    <label>Typ des Anliegens</label>
                    <MSelect name="kindOfRequest"
                             value={kindOfRequest}
                             onChange={(e) => setKindOfRequest(e)}>
                        <option>-</option>
                        <option>Allgemeines Anliegen</option>
                        <option>Probleme mit einem Produkt</option>
                        <option>Verbesserungsvorschlag für ein Produkt</option>
                        <option>Anliegen zum Thema Datenschutz</option>
                    </MSelect>
                    {kindOfRequest == null || kindOfRequest <= 0 &&
                        <MParameterHint>Bitte Typ des Anliegens wählen!</MParameterHint>}
                </MParameter>
                <MParameter style={kindOfRequest !== 2 && kindOfRequest !== 3 ? {display: "none"} : {}}>
                    <label>Produkt</label>
                    <MSelect name="product"
                             value={productId}
                             onChange={(e) => setProductId(e)}>
                        <option>-</option>
                        <option>Slidari Rush</option>
                    </MSelect>
                </MParameter>
                <MParameter style={productId === undefined || productId <= 0 ? {display: "none"} : {}}>
                    <MInput name="productVersion"
                            type="text"
                            value={productVersion}
                            onChange={(e) => setProductVersion(e.target.value)}
                            labelContent="Produktversion (optional)"
                            placeholder={"Produktversion ..."}/>
                </MParameter>
                <MParameter style={kindOfRequest === undefined || kindOfRequest <= 0 ? {display: "none"} : {}}>
                    <MTextArea name="description"
                               value={text}
                               onChange={(e) => setText(e.target.value)}
                               labelContent="Beschreibung"
                               validators={[MInputValidationRules.required]}/>
                </MParameter>
                <MButton onClick={onSendRequestClicked} disabled={!isInputValid()}>Anfrage senden</MButton>
            </MInputForm>
        </>
    }

    function renderCaptcha() {
        if (captchaExpected) {
            return <MCaptcha verified={captchaVerified}/>;
        }

        return <></>;
    }

    function renderSendingDoneHint() {
        if (!requestSent) {
            return <></>;
        }

        return <MHint title="Vielen Dank für deine Anfrage!"
                      description="Wird haben deine Anfrage erhalten, und werden diese so schnell es geht bearbeiten."
                      statusStyle={MStatusStyle.Nice}
                      style={{marginTop: "12px"}}/>
    }

    function renderApiUnavailable() {
        return <>
            <MHint title={"Dienst nicht Verfügbar!"}
                   description={"Der Dienst zur einfachen Kontaktaufnahme steht aktuell nicht zur Verfügung! Warte einen Moment, und versuche es erneut, oder nimm per E-Mail Kontakt mit uns auf."}
                   statusStyle={MStatusStyle.Warning}
                   style={{marginTop: "10px"}}/>
        </>
    }

    return <MBasePage pageTitle="Kontakt">
        <SmoothFadedContent>
            <MFormattedTextContent style={{margin: "auto", maxWidth: "600px"}}>
                <h1>Kontakt</h1>
                <p>Hier hast du die Möglichkeit Kontakt mit uns aufzunehmen. Um dein Anliegen schnellstmöglich
                    bearbeiten zu können,
                    benötigen wir einige Informationen, welche dein Anliegen beschreiben.</p>
                <ServiceAvailableChecker url={SlidariEmailApi.getUrl() + "email/api-status"}
                                         unavailableHint={renderApiUnavailable()}>

                    {renderInputForm()}
                    {renderCaptcha()}
                    {renderSendingDoneHint()}
                </ServiceAvailableChecker>
            </MFormattedTextContent>
        </SmoothFadedContent>
    </MBasePage>;
}
