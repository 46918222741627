import {MBasePage} from "../core/MBasePage";
import "./SlidariRushPage.css";
import {BrowserView, MobileView} from 'react-device-detect';

import {MVideo} from "../../components/core/MVideo";
import {MFormattedTextContent} from "../../components/core/MFormattedTextContent";
import MScrollReveal from "../../components/core/MScrollReveal";

export function SlidariRushPage() {
    const videoShare = "https://slidari-world.com/api.slidari/v1/assets/video/slidari-rush/"
    const videoRation = 19.5 / 9;

    const imageShare = "https://slidari-world.com/api.slidari/v1/assets/image/slidari-rush/"

    /**
     * Videoplayer rendern
     * @param src
     */
    function renderVideoPlayer(src: string) {
        return <MVideo src={src}
                       autoPlay={true}
                       loop={true}
                       muted={true}
                       controlsList="nofullscreen"
                       playsInline={true}
                       aspectRatio={videoRation}/>
    }

    return <MBasePage className="no-margin" pageTitle="Slidari Rush">
        <MScrollReveal style={{margin: "auto", marginTop: "0", maxWidth: "2600px"}}
                       threshold={0.3}>
            <BrowserView>
                <MVideo src={videoShare + "SlidariWorldTeaser.mp4"}
                        autoPlay={true}
                        loop={true}
                        muted={true}
                        controlsList="nofullscreen"
                        playsInline={true}
                        aspectRatio={16 / 9}/>
            </BrowserView>
            <MobileView>
                <MVideo src={videoShare + "SlidariWorldTeaser_LQ.mp4"}
                        autoPlay={true}
                        loop={true}
                        muted={true}
                        controlsList="nofullscreen"
                        playsInline={true}
                        aspectRatio={16 / 9}/>
            </MobileView>
        </MScrollReveal>
        <MScrollReveal threshold={0.2} style={{marginTop: "30px", marginBottom: "30px"}}>
            <div className="main-title-container">
                <p>Slidari</p>
                <p>Rush</p>
            </div>
        </MScrollReveal>
        <MFormattedTextContent>
            <div className="m-page-content" style={{marginTop: "0"}}>
                <MScrollReveal className="description-part" threshold={0.3} offset={-4000}>
                    <div className="video-and-text">
                        <p>
                            Tauche ein in ein aufregendes Abenteuer, in dem du durch faszinierende Welten rasen,
                            mächtige Items sammeln und mit dem sammeln Pace deinen Fortschritt steigern kannst.
                        </p>
                        <p>
                            Egal, ob du gefährliche Wüsten, eisige Berge oder mysteriöse Städte erkundest –
                            in Slidari Rush erwartet dich endloser Nervenkitzel und die Herausforderung, immer
                            weiter zu kommen. Wie weit wirst du es schaffen?
                        </p>
                    </div>
                </MScrollReveal>
                <MScrollReveal className="description-part" threshold={0.3}>
                    <h1 className="sub-title">Bleib in der Spur!</h1>
                    <div className="video-and-text">
                        <p>
                            Reagiere blitzschnell und weiche Hindernissen geschickt aus!
                        </p>
                        <p>
                            Springe, ducke dich und nutze all deine Fähigkeiten, um unaufhaltsam voranzukommen.
                            Jede Bewegung zählt – bleib konzentriert und halte dich auf der Spur!
                        </p>
                        <BrowserView>
                            {renderVideoPlayer(videoShare + "Movement_001.mp4")}
                        </BrowserView>
                        <MobileView>
                            {renderVideoPlayer(videoShare + "Movement_001_LQ.mp4")}
                        </MobileView>
                    </div>
                </MScrollReveal>
                <MScrollReveal className="description-part" threshold={0.3}>
                    <h1 className="sub-title">Sei kreativ!</h1>
                    <div className="video-and-text">
                        <h2 className="sub-title-2">Gestalte deinen Avatar</h2>
                        <p>
                            Du kannst jedes Detail deines Avatars anpassen - von der Frisur bis zur Kleidung.
                        </p>
                        <p>
                            Erkunde Welten, sammele Pace und kaufe dir coole neue Dinge um deinen Avatar noch
                            einzigartiger zu machen.
                        </p>
                        <BrowserView>
                            {renderVideoPlayer(videoShare + "ModifyAvatar_001.mp4")}
                        </BrowserView>
                        <MobileView>
                            {renderVideoPlayer(videoShare + "ModifyAvatar_001_LQ.mp4")}
                        </MobileView>
                    </div>
                </MScrollReveal>
                <MScrollReveal className="description-part" threshold={0.3}>
                    <h1 className="sub-title">Meistere neue Fertigkeiten</h1>
                    <div className="video-and-text">
                        <p>
                            Endecke neue Möglichkeiten, indem du Fertigkeitspunkte sammelst und neue nützliche
                            Fertigkeiten erlernst.
                        </p>
                        <BrowserView>
                            {renderVideoPlayer(videoShare + "UnlockSkills_001.mp4")}
                        </BrowserView>
                        <MobileView>
                            {renderVideoPlayer(videoShare + "UnlockSkills_001_LQ.mp4")}
                        </MobileView>
                    </div>
                </MScrollReveal>
                <MScrollReveal className="description-part" threshold={0.3}>
                    <h1 className="sub-title">Jage den Highscore</h1>
                    <div className="video-and-text">
                        <p>
                            Teste deine Grenzen, vergleiche dich mit anderen und schreibe deinen Namen in die Geschichte
                            des Spiels. Viel Erfolg und Spaß beim Rekorde brechen!
                        </p>
                        <img src={imageShare + "Highscore_001.png"} alt={"highscore"}/>
                    </div>
                </MScrollReveal>
                <hr/>
            </div>
        </MFormattedTextContent>
    </MBasePage>;
}
