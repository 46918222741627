import {ReactNode, useEffect, useState} from "react";

import {MApiAdapter} from "../api/MApiAdapter";
import {IMComponentProps} from "./core/IMComponentProps";

interface IServiceUnavailableCheckerProps extends IMComponentProps<HTMLElement> {
    url: string;
    unavailableHint?: ReactNode;
}

export function ServiceAvailableChecker({url, unavailableHint, children}: IServiceUnavailableCheckerProps) {
    const [isServiceAvailable, setIsServiceAvailable] = useState<boolean>(true);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        let intervalId: number | any;

        const checkServiceAvailability = async () => {
            try {
                const data = await MApiAdapter.fetchFromApi(url);
                if (data.status === 200) {
                    setIsServiceAvailable(true);
                    setLoading(false);
                    if (intervalId) clearInterval(intervalId);
                } else {
                    setIsServiceAvailable(false);
                }
            } catch (error) {
                console.error('Fehler beim Abrufen des Dienststatus:', error);
                setIsServiceAvailable(false);
            }
        };

        checkServiceAvailability().then();

        if (!isServiceAvailable) {
            intervalId = setInterval(checkServiceAvailability, 4000);
        }

        return () => {
            if (intervalId) {
                clearInterval(intervalId);
            }
        };
    }, [isServiceAvailable]);

    return <>
        {isServiceAvailable && !loading && children}
        {!isServiceAvailable && unavailableHint}
    </>;
}
