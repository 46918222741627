import {MApiResponse} from "./MApiResponse";
import {MApiAdapter} from "./MApiAdapter";
import {MUser} from "../types/core/MUser";

import config from '../config';

export class SlidariDataApi {
    static async fetchGameData<T>(ownerId: string, groupId: string, jwt: string = ""): Promise<MApiResponse<T>> {
        let url = this.getUrl() + "game-data/owner/" + ownerId + "/group/" + groupId;
        return await MApiAdapter.fetchFromApi(url, jwt);
    }

    static async fetchUser<T>(userId: string, jwt: string = ""): Promise<MApiResponse<T>> {
        let url = this.getUrl() + "users/" + userId;
        return await MApiAdapter.fetchFromApi(url, jwt);
    }

    static async createUser<T>(user: MUser, jwt: string = ""): Promise<MApiResponse<T>> {
        let url = this.getUrl() + "users";
        return await MApiAdapter.postToApi(url, jwt, user);
    }

    static async updateUser<T>(user: MUser, jwt: string = ""): Promise<MApiResponse<T>> {
        let url = this.getUrl() + "users";
        return await MApiAdapter.putToApi(url, jwt, user);
    }

    static async markUserDeleted<T>(userId: string, jwt: string = ""): Promise<MApiResponse<T>> {
        let url = this.getUrl() + "users/" + userId + "/mark-as-deleted";
        return await MApiAdapter.patchAtApi(url, jwt);
    }

    static getUrl() {
        if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
            return config.dataApi.develop;
        } else {
            return config.dataApi.production;
        }
    }
}

