import React, {
    ReactNode,
    useState,
    useImperativeHandle,
    forwardRef,
    isValidElement,
    CSSProperties, ReactElement, useEffect
} from 'react';
import {IMInputProps, MInput} from './MInput';
import {IMTextAreaProps, MTextArea} from "./MTextArea";
import {MParameter} from "./MParameter";
import {MInputValidatorWithMeta} from "./MValidationUtilities";

interface IMInputFormProps {
    style?: CSSProperties;
    children?: ReactNode;
    onValidate?: (isValid: boolean, formData: { [key: string]: string }) => void;
}

/**
 * Form Komponente, welche MParameter mit darin befindlichen MInputs enthalten kann und diese validiert.
 */
export const MInputForm = forwardRef((props: IMInputFormProps, ref) => {
    const {children, onValidate} = props;
    const [formData, setFormData] = useState<{ [key: string]: string }>({});
    const [formErrors, setFormErrors] = useState<{ [key: string]: string | undefined }>({});

    useImperativeHandle(ref, () => ({
        triggerValidation: handleTriggerValidation
    }));

    useEffect(() => {
        validateInputs();
    }, []);

    /**
     * Prüft, ob das Element vom Typ MParameter ist
     * @param element
     */
    const isParameter = (element: ReactElement): element is ReactElement => {
        return element.type === MParameter;
    };

    /**
     * Prüft, ob das Element vom Typ MInput ist
     * @param element
     */
    const isInput = (element: React.ReactElement): element is React.ReactElement<IMInputProps> => {
        return element.type === MInput;
    };

    /**
     * Prüft, ob das Element vom Typ MTextArea ist
     * @param element
     */
    const isTextArea = (element: React.ReactElement): element is React.ReactElement<IMTextAreaProps> => {
        return element.type === MTextArea;
    };

    /**
     * Validiert alle MInput Werte gegen die Validierungsvorgaben
     */
    const validateInputs = (): boolean => {
        const errors: { [key: string]: string | undefined } = {};
        let isValid = true;

        const extractInputsFromParameter = (node: ReactNode) => {
            if (isValidElement(node) && isParameter(node)) {
                return React.Children.map(node.props.children, child => {
                    if (isValidElement(child) && (isInput(child) || isTextArea(child))) {
                        const {name, validators} = child.props as any;
                        const value = child.props.value || '';

                        if (validators) {
                            for (const validator of validators as MInputValidatorWithMeta[]) {
                                const error = validator(String(value));
                                if (error) {
                                    errors[name] = error;
                                    isValid = false;
                                    break;
                                }
                            }
                        }
                    }
                });
            }
        };

        React.Children.forEach(children, child => {
            extractInputsFromParameter(child);
        });

        setFormErrors(errors);
        return isValid;
    };

    /**
     * Wird bei Änderung eines Feldes aufgerufen
     * @param name
     * @param value
     */
    const handleInputChange = (name: string, value: string) => {
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    /**
     * Löst die Validierung aus und ruft anschließend den Callback auf
     */
    const handleTriggerValidation = () => {
        const isValid = validateInputs();
        if (onValidate != null) {
            onValidate(isValid, formData);
        }
    };

    /**
     * Renders alle Kind-Elemente vom Typ MInput neu
     */
    const renderChildren = () => {
        return React.Children.map(children, child => {
            if (isValidElement(child)) {
                if (isParameter(child)) {
                    return React.cloneElement(child, {}, React.Children.map(child.props.children, nestedChild => {
                        if (isValidElement(nestedChild) && isInput(nestedChild)) {
                            const name = nestedChild.props.name;
                            return React.cloneElement(nestedChild, {
                                value: nestedChild.props.value || '',
                                onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                                    handleInputChange(name, e.target.value);
                                    if (nestedChild.props.onChange) {
                                        nestedChild.props.onChange(e);
                                    }
                                },
                                errorMessage: formErrors[name],
                            } as Partial<IMInputProps>);
                        } else if (isValidElement(nestedChild) && isTextArea(nestedChild)) {
                            const name = nestedChild.props.name;
                            return React.cloneElement(nestedChild, {
                                value: nestedChild.props.value || '',
                                onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => {
                                    handleInputChange(name, e.target.value);
                                    if (nestedChild.props.onChange) {
                                        nestedChild.props.onChange(e);
                                    }
                                },
                                errorMessage: formErrors[name],
                            } as Partial<IMTextAreaProps>);
                        }

                        return nestedChild;
                    }));
                }

                return child;
            }

            return child;
        });
    };

    return <div style={props.style}>
        {renderChildren()}
    </div>;
});