import {MBasePage} from "./core/MBasePage";
import "./GamesPage.css";

import {SmoothFadedContent} from "../components/SmoothFadedContent";
import {MFlexPanel} from "../components/core/MFlexPanel";
import {GamePanel} from "../components/GamePanel";

export function GamesPage() {
    const imageShare = "https://slidari-world.com/api.slidari/v1/assets/image/slidari-rush/"

    return <MBasePage pageTitle="Spiele" className="games-page">
        <SmoothFadedContent>
            <MFlexPanel>
                <GamePanel title={"Slidari Rush"}
                           gamePageUrl={"slidari-rush"}
                           keywords={["Endless Runner", "Action", "Adventure"]}
                           screenshotUrls={[imageShare + "Screen-001.png", imageShare + "Screen-002.png", imageShare + "Screen-003.png", imageShare + "Screen-004.png", imageShare + "Screen-005.png"]}/>
            </MFlexPanel>
        </SmoothFadedContent>
    </MBasePage>;
}
