import "./MPushMessage.css";
import {MStatusStyle} from "./MStatusStyle";
import {MHint} from "./MHint";

interface IMPushMessageProps {
    title?: string;
    message?: string | null;
    statusStyle?: MStatusStyle | null;
    onClose?: () => void;
}

const MPushMessage = ({title, message, statusStyle, onClose}: IMPushMessageProps) => {
    if (statusStyle == null) {
        statusStyle = MStatusStyle.Default;
    }

    function getClassName() {
        let className = "m-push-message";
        switch (statusStyle) {
            case MStatusStyle.Default:
                break;
            case MStatusStyle.Nice:
                className += " nice";
                break;
            case MStatusStyle.Warning:
                className += " warning";
                break;
            case MStatusStyle.Error:
                className += " error";
                break;
        }

        return className;
    }

    return <div className={getClassName()}>
        <MHint title={title} description={message} renderBorder={false}/>
        <button onClick={onClose}>X</button>
    </div>;
};

export default MPushMessage;