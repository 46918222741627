const appConfig = {
    dataApi: {
        develop: "https://slidari-world.com/api.slidari/v1/",
        production: "https://slidari-world.com/api.slidari/v1/",
    },
    webMailerApi: {
        develop: "https://slidari-world.com/api.slidari/v1/",
        production: "https://slidari-world.com/api.slidari/v1/",
    }
};

export default appConfig;