import {MBasePage} from "./core/MBasePage";
import {MFirebaseUserManagementPanel} from "../components/firebase/MFirebaseUserManagementPanel";
import {MFlexPanel} from "../components/core/MFlexPanel";
import {ServiceAvailableChecker} from "../components/ServiceAvailableChecker";
import {MHint} from "../components/core/MHint";
import {SmoothFadedContent} from "../components/SmoothFadedContent";

import {MStatusStyle} from "../components/core/MStatusStyle";
import {SlidariDataApi} from "../api/SlidariDataApi";

export function LoginPage() {
    function renderApiUnavailable() {
        return <>
            <h1>Benutzerverwaltung</h1>
            <MHint title={"Dienst nicht Verfügbar!"}
                   style={{marginTop: "12px"}}
                   description={<>Der Dienst zur Benutzerverwaltung steht aktuell nicht zur Verfügung!<br/>Warte einen
                       Moment, und versuche es erneut.</>}
                   statusStyle={MStatusStyle.Warning}/>
        </>
    }

    return <MBasePage pageTitle="Benutzerprofil">
        <SmoothFadedContent>
            <MFlexPanel>
                <ServiceAvailableChecker url={SlidariDataApi.getUrl() + "api-status"}
                                         style={{marginTop: "10px"}}
                                         unavailableHint={renderApiUnavailable()}>
                    <MFirebaseUserManagementPanel/>
                </ServiceAvailableChecker>
            </MFlexPanel>
        </SmoothFadedContent>
    </MBasePage>;
}
