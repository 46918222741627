import "./MSlideshow.css";

import {IMInteractiveComponentProps} from "./IMInteractiveComponentProps";
import {HTMLAttributes, useEffect, useState,} from "react";

interface IMSlideshowProps extends IMInteractiveComponentProps<HTMLElement>, HTMLAttributes<HTMLElement> {
    imageUrls: string[] | undefined;
    transitionDuration?: number;
}

export function MSlideshow({imageUrls, transitionDuration, ...props}: IMSlideshowProps) {
    const [currentIndex, setCurrentIndex] = useState<number>(0);

    if (transitionDuration == null) {
        transitionDuration = 4000;
    }

    useEffect(() => {
        if (imageUrls == null || imageUrls.length == 0) {
            return;
        }

        const intervalId = setInterval(() => {
            setCurrentIndex((prevIndex) => (prevIndex + 1) % imageUrls.length);
        }, transitionDuration);

        return () => clearInterval(intervalId);
    }, [imageUrls]);

    if (imageUrls == null) {
        return <></>;
    }

    return <div className="m-slideshow" {...props}>
        <img src={imageUrls[currentIndex]} alt={`Slide ${currentIndex + 1}`}/>
    </div>;
}