import "./MHamburger.css";

import {ButtonHTMLAttributes} from "react";

interface IMHamburgerProps extends ButtonHTMLAttributes<HTMLElement> {
}

export function MHamburger(props: IMHamburgerProps) {
    return <div className="m-hamburger" {...props}>
        <div></div>
        <div></div>
        <div></div>
    </div>
}