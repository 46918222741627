import React, {useEffect, useRef, useState} from 'react';
import "./MCaptcha.css";
import rotateIcon from "./../../assets/images/Rotate.svg";

import {MInput} from "./MInput";
import {MParameterHint} from "./MParameterHint";
import {MButton} from "./MButton";

export interface IMCaptchaProps {
    textLength?: number;
    numberOfRandomLines?: number;
    verified: (isVerified: boolean) => void;
}

function MCaptcha({textLength, numberOfRandomLines, verified}: IMCaptchaProps) {
    const [captcha, setCaptcha] = useState<string>(generateCaptchaCode());
    const [userInput, setUserInput] = useState<string>("");
    const [error, setInputError] = useState<string>("");

    const canvasRef = useRef<HTMLCanvasElement | null>(null);

    useEffect(() => {
        generateCaptchaImage();
    }, [captcha]);

    /**
     *
     */
    function generateCaptchaImage() {
        const canvas = canvasRef.current;
        if (!canvas) {
            return;
        }

        const ctx = canvas.getContext("2d");
        if (!ctx) {
            return;
        }

        // Text erzeugen ------------------------
        const fontSize = 11;
        ctx.font = `${fontSize}px Arial`;
        const textMetrics = ctx.measureText(captcha);
        const textWidth = textMetrics.width;
        const textHeight = fontSize;
        const padding = 12;

        canvas.width = textWidth + padding * 2;
        canvas.height = textHeight + padding * 2;
        ctx.clearRect(0, 0, canvas.width, canvas.height);

        // Random Linien erzeugen ---------------
        if (numberOfRandomLines == null) {
            numberOfRandomLines = 2;
        }

        if (numberOfRandomLines > 0) {
            ctx.strokeStyle = getRandomColor();
            ctx.lineWidth = 1;
            for (let i = 0; i < numberOfRandomLines; i++) {
                const startX = Math.random() * canvas.width;
                const startY = Math.random() * canvas.height;
                const endX = Math.random() * canvas.width;
                const endY = Math.random() * canvas.height;
                ctx.beginPath();
                ctx.moveTo(startX, startY);
                ctx.lineTo(endX, endY);
                ctx.stroke();
            }
        }

        // Text erzeugen und positionieren ---------------
        ctx.fillStyle = getRandomColor();
        const x = padding;
        const y = padding + fontSize;
        ctx.fillText(captcha, x, y);
        const tempCanvas = document.createElement("canvas");
        tempCanvas.width = canvas.width;
        tempCanvas.height = canvas.height;
        const tempCtx = tempCanvas.getContext("2d");
        if (tempCtx == null) {
            return;
        }

        tempCtx.drawImage(canvas, 0, 0);

        ctx.clearRect(0, 0, canvas.width, canvas.height);
        ctx.translate(canvas.width / 2, canvas.height / 2);

        // Textrotation verändern   --------------------
        const angle = (Math.random() - 0.5) * 0.4;
        const skewX = (Math.random() - 0.5) * 0.2;
        const scaleY = 1 + (Math.random() - 0.5) * 0.2;
        ctx.rotate(angle);

        ctx.transform(1, skewX, 0, scaleY, 0, 0);
        ctx.drawImage(tempCanvas, -canvas.width / 2, -canvas.height / 2);
        ctx.setTransform(1, 0, 0, 1, 0, 0);
    }

    /**
     * Gibt eine zufällige Farbe zurück
     */
    const getRandomColor = () => {
        const letters = '0123456789ABCDEF';
        let color = '#';
        for (let i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * 16)];
        }

        return color;
    };

    /**
     * Captcha erzeugen
     */
    function generateCaptchaCode(): string {
        if (textLength == null) {
            textLength = 8;
        }

        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let result = '';
        for (let i = 0; i < textLength; i++) {
            result += characters.charAt(Math.floor(Math.random() * characters.length));
        }

        return result;
    }

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setUserInput(e.target.value);
    };

    const validateCaptcha = () => {
        let status = false;
        if (userInput === captcha) {
            setInputError("");
            setCaptcha(generateCaptchaCode());
            setUserInput("");
            status = true;
        } else {
            setInputError("Die Eingabe war unzulässig!");
            setUserInput("");
        }

        if (verified) {
            verified(status);
        }
    };

    function recreateCaptcha() {
        setCaptcha(generateCaptchaCode());
        setInputError("");
        setUserInput("");
    }

    function onValidateCaptchaClicked() {
        validateCaptcha()
    }

    function onRecreateCaptchaClicked() {
        recreateCaptcha();
    }

    return <div className="m-captcha">
        <div id="captchaImage">
            <canvas ref={canvasRef}/>
        </div>
        <div id="captchaInput">
            <MInput name="captcha"
                    type="text"
                    placeholder="Captcha eingeben"
                    value={userInput}
                    onChange={handleInputChange}/>
            <MButton onClick={onRecreateCaptchaClicked}><img src={rotateIcon} alt={"rotate"}/></MButton>
            <MButton onClick={onValidateCaptchaClicked}>Verifizieren</MButton>
        </div>
        {error && <MParameterHint>{error}</MParameterHint>}
    </div>;
}

export default MCaptcha;