export class MApiResponse<T> {
    constructor(status: number, data: T | null, error: string | null = null) {
        this.status = status;
        this.data = data;
        this.error = error;
    }

    status: number;
    data: T | null;
    error: string | null;

    hasData() {
        return this.data != null;
    }
}