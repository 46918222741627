// Import the functions you need from the SDKs you need
import {initializeApp} from "firebase/app";
import {getAuth} from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
// Your web app's Firebase configuration

const firebaseConfig = {
    apiKey: "AIzaSyCyssUOQ2nuZ0Mx-zGQCt8ctiZglV3_jA8",
    authDomain: "slidari-world.firebaseapp.com",
    projectId: "slidari-world",
    storageBucket: "slidari-world.appspot.com",
    messagingSenderId: "647581347404",
    appId: "1:647581347404:web:478f711622674c66c4cee9",
    measurementId: "G-MGPK6MBFJ6"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Authentication and get a reference to the service
export const auth = getAuth(app);
export default app;