import "./MVideo.css";

import {IMInteractiveComponentProps} from "./IMInteractiveComponentProps";
import {VideoHTMLAttributes} from "react";

interface IMVideoProps extends IMInteractiveComponentProps<HTMLVideoElement>, VideoHTMLAttributes<HTMLVideoElement> {
    aspectRatio?: number; // Prop für das Seitenverhältnis
}

export function MVideo({aspectRatio = 16 / 9, style, ...props}: IMVideoProps) {
    // Berechne das Padding-Top basierend auf dem Seitenverhältnis
    const paddingTop = `${100 / aspectRatio}%`;

    return <div className="m-video" style={{...style, paddingTop}}>
        <video  {...props}/>
    </div>;
}