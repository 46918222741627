import {useEffect, useState} from "react";
import "./MHeader.css";

import {MNavigation, MNavigationItem} from "./MNavigation";

import {IMComponentProps} from "./IMComponentProps";
import {IMTitledComponentProps} from "./IMTitledComponentProps";

interface IMHeaderProps extends IMComponentProps<HTMLElement>, IMTitledComponentProps {
    userStateSection?: any;
    navigationItems: MNavigationItem[]
}

export function MHeader({userStateSection, navigationItems, style, onClick}: IMHeaderProps) {
    const [isPageScrolled, setIsPageScrolled] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 20) {
                setIsPageScrolled(true);
            } else {
                setIsPageScrolled(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    function renderUserStateSection() {
        if (userStateSection == null) {
            return <></>;
        }

        return userStateSection;
    }

    return <header className={isPageScrolled ? "m-header page-scrolled" : "m-header"} style={style} onClick={onClick}>
        <div id="headerElements">
            <MNavigation items={navigationItems}/>
            {renderUserStateSection()}
        </div>
    </header>;
}