import * as React from "react";
import "./MFooter.css";

import {MLink} from "./MLink";

import {IMComponentProps} from "./IMComponentProps";

interface IMFooterProps extends IMComponentProps<HTMLElement> {
}

export function MFooter({style, onClick}: IMFooterProps) {
    return <footer className="m-footer" style={style} onClick={onClick}>
        <div>
            <div className="m-footer-links">
                <MLink to={"/impressum/"}>Impressum</MLink>
                <MLink to={"/privacy-policy/"}>Datenschutz</MLink>
                <MLink to={"/contact/"}>Kontakt</MLink>
            </div>
            <hr/>
            <div className="copyright">
                <span>&copy;Slidari 2024</span>
            </div>
        </div>
    </footer>;
}