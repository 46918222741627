import {HTMLAttributes} from "react";
import "./MParameterHint.css";

export interface IMParameterHintProps extends HTMLAttributes<HTMLElement> {
}

export function MParameterHint({children, ...props}: IMParameterHintProps) {
    if (children == null) {
        return <span className="m-parameter-hint" style={{opacity: 0}} {...props}></span>;
    }

    return <span className="m-parameter-hint" {...props}>{children}</span>;
}