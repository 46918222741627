export class MFirebaseUser {
    constructor(userId: string, mailAddress: string | null = null, mailVerified: boolean = false) {
        this.userId = userId;
        this.mailAddress = mailAddress;
        this.mailVerified = mailVerified;
    }

    userId?: string;
    mailAddress?: string | null;
    mailVerified: boolean;
}