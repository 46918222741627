import "./MPushMessageContainer.css";

interface IMPushMessageContainerProps {
    children?: any;
}

export function MPushMessageContainer({children}: IMPushMessageContainerProps) {
    return <div className="m-push-message-container">
        {children}
    </div>;
}