import "./MFormattedTextContent.css";

import {IMComponentProps} from "./IMComponentProps";

interface IMLimitedPageContentProps extends IMComponentProps<HTMLElement> {
}

export function MFormattedTextContent({children, ...props}: IMLimitedPageContentProps) {
    return <div className="m-formatted-text-content" {...props}>
        {children}
    </div>;
}