import {MBasePage} from "./core/MBasePage";
import {MFormattedTextContent} from "../components/core/MFormattedTextContent";
import {SmoothFadedContent} from "../components/SmoothFadedContent";

export function ImpressumPage() {
    return <MBasePage pageTitle="Impressum">
        <SmoothFadedContent>
            <MFormattedTextContent style={{margin: "auto", maxWidth: "800px"}}>
                <h1>Impressum</h1>
                <p>
                    Im Folgenden finden Sie das Impressum, das alle gesetzlich vorgeschriebenen Angaben zu den
                    Verantwortlichen für diese Webseite enthält. Hier erfahren Sie, wer hinter den Inhalten dieser Seite
                    steht und wie Sie uns bei Fragen, Anregungen oder rechtlichen Anliegen erreichen können. Unser Ziel
                    ist es, Ihnen transparente und verlässliche Informationen zu bieten.
                </p>

                <h2>Angaben gemäß § 5 TMG</h2>
                <p>
                    Joscha Boldt<br/>
                    Bleichstr. 92<br/>
                    67655 Kaiserslautern<br/>
                </p>

                <h2>Kontakt</h2>
                <p>
                    E-Mail: <a href="mailto:info@slidari-world.com">info@slidari-world.com</a><br/>
                </p>

                <h2>Verantwortlich für den Inhalt nach § 55 Abs. 2 RStV</h2>
                <p>
                    Joscha Boldt<br/>
                    Bleichstr. 92<br/>
                    67655 Kaiserslautern<br/>
                </p>

                <h2>Haftungsausschluss</h2>
                <h3>Haftung für Inhalte</h3>
                <p>
                    Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den
                    allgemeinen
                    Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht
                    verpflichtet,
                    übermittelte oder gespeicherte fremde Informationen zu überwachen oder nach Umständen zu forschen,
                    die
                    auf
                    eine rechtswidrige Tätigkeit hinweisen. Verpflichtungen zur Entfernung oder Sperrung der Nutzung von
                    Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt. Eine diesbezügliche Haftung
                    ist
                    jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich. Bei
                    Bekanntwerden
                    von
                    entsprechenden Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.
                </p>

                <h3>Haftung für Links</h3>
                <p>
                    Unser Angebot enthält Links zu externen Webseiten Dritter, auf deren Inhalte wir keinen Einfluss
                    haben.
                    Deshalb können wir für diese fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der
                    verlinkten
                    Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten verantwortlich. Die verlinkten
                    Seiten
                    wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte
                    waren
                    zum
                    Zeitpunkt der Verlinkung nicht erkennbar. Eine permanente inhaltliche Kontrolle der verlinkten
                    Seiten
                    ist
                    jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von
                    Rechtsverletzungen werden wir derartige Links umgehend entfernen.
                </p>

                <h3>Urheberrecht</h3>
                <p>
                    Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem
                    deutschen
                    Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb
                    der
                    Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des jeweiligen Autors bzw.
                    Erstellers.
                    Downloads und Kopien dieser Seite sind nur für den privaten, nicht kommerziellen Gebrauch gestattet.
                    Soweit
                    die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte Dritter
                    beachtet.
                    Insbesondere werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine
                    Urheberrechtsverletzung aufmerksam werden, bitten wir um einen entsprechenden Hinweis. Bei
                    Bekanntwerden
                    von
                    Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen.
                </p>
            </MFormattedTextContent>
        </SmoothFadedContent>
    </MBasePage>;
}
