import {MPanel} from "./core/MPanel";
import {useEffect, useState} from "react";
import {usePushMessage} from "./core/MPushMessageProvider";
import {MHint} from "./core/MHint";
import {WebManagedDataset, WebManagedDatasetPanel} from "./WebManagedDatasetPanel";
import {onAuthStateChanged} from "firebase/auth";
import {SlidariRushDataApi} from "../api/SlidariRushDataApi";
import {MJsonImage} from "./core/MJsonImage";

import {auth} from "../Firebase";
import {MParameter} from "./core/MParameter";

export interface ISaveGamePanelProps {
    gameTitle: string;
}

export function SlidariRushManagerPanel({gameTitle}: ISaveGamePanelProps) {
    const [isLoadingSaveGame, setIsLoadingSaveGame] = useState<boolean>(false);
    const [loadingSaveGameStatus, setLoadingSaveGameStatus] = useState<number>(0);
    const [saveGame, setSaveGame] = useState<WebManagedDataset | null>();
    const [isLoadingAvatarPhoto, setIsLoadingAvatarPhoto] = useState<boolean>(false);
    const [loadingAvatarPhotoStatus, setLoadingAvatarPhotoStatus] = useState<number>(0);
    const [avatarPhoto, setAvatarPhoto] = useState<WebManagedDataset | null>();
    const [avatarPhotoSrc, setAvatarPhotoJson] = useState(null);

    const pushMessage = usePushMessage();

    useEffect(() => {
        const timeoutId = setInterval(async () => {
            await updateSaveGame();
        }, 5000);

        return () => clearInterval(timeoutId);
    }, []);

    useEffect(() => {
        const timeoutId = setInterval(async () => {
            await updateAvatarPhoto();
        }, 9000);

        return () => clearInterval(timeoutId);
    }, []);

    useEffect(() => {
        onAuthStateChanged(auth, async (user) => {
            if (user != null) {
                await updateSaveGame();
                await updateAvatarPhoto();
            }
        });
    }, []);

    async function updateSaveGame() {
        try {
            setIsLoadingSaveGame(true);
            await loadSaveGame()
        } catch (ex) {

        } finally {
            setIsLoadingSaveGame(false);
        }
    }

    async function updateAvatarPhoto() {
        try {
            setIsLoadingAvatarPhoto(true);
            await loadAvatarPhoto()
        } catch (ex) {

        } finally {
            setIsLoadingAvatarPhoto(false);
        }
    }

    async function loadSaveGame() {
        if (auth.currentUser == null) {
            return;
        }

        const token = await auth.currentUser.getIdToken();
        const apiResponse = await SlidariRushDataApi.fetchSaveGame<WebManagedDataset>(auth.currentUser.uid, token);

        if (apiResponse.status === 200) {
            setSaveGame(apiResponse.data);
        } else {
            setSaveGame(null);
        }

        setLoadingSaveGameStatus(apiResponse.status);
    }

    async function loadAvatarPhoto() {
        if (auth.currentUser == null) {
            return;
        }

        const token = await auth.currentUser.getIdToken();
        const apiResponse = await SlidariRushDataApi.fetchAvatarPhoto<WebManagedDataset>(auth.currentUser.uid, token);

        if (apiResponse.status === 200) {
            setAvatarPhoto(apiResponse.data);
            if (apiResponse.data != null && apiResponse.data.jsonData != null) {
                const jsonObject = JSON.parse(apiResponse.data.jsonData);
                if ('photoJson' in jsonObject) {
                    const photoJson = (jsonObject as any).photoJson;
                    setAvatarPhotoJson(apiResponse.data.jsonData);
                } else {
                    setAvatarPhotoJson(null);
                }
            } else {
                setAvatarPhotoJson(null);
            }
        } else {
            setAvatarPhoto(null);
        }

        setLoadingAvatarPhotoStatus(apiResponse.status);
    }

    function renderSaveGame() {
        return <div style={{marginBottom: "8px"}}>
            <WebManagedDatasetPanel dataset={saveGame}
                                    loadingStatus={loadingSaveGameStatus}
                                    gameTitle={gameTitle}/>
            {renderSaveGameDetails()}
            {isLoadingSaveGame && saveGame == null && <MHint title={"Spielstand laden ..."}/>}
        </div>
    }

    function renderSaveGameDetails() {
        if (saveGame == null) {
            return <></>;
        }

        const saveGameDetail = JSON.parse(saveGame?.jsonData);
        if (saveGameDetail == null) {
            return <></>;
        }

        const worldMetrics = saveGameDetail.worldMetrics;
        const totalTravelledDistance = worldMetrics.totalTravelledDistance;
        const totalCollectedCoins = worldMetrics.totalCollectedCoins;
        const successfullyPlayedWorlds = worldMetrics.successfullyPlayedWorlds;

        return <div style={{marginTop: "12px"}}>
            <MParameter>
                <label>Gespielte Welten</label>
                <span>{successfullyPlayedWorlds <= 0 ? "Keine" : successfullyPlayedWorlds}</span>
            </MParameter>
            <MParameter>
                <label>Zurückgelegte Strecke</label>
                <span>{totalTravelledDistance}m</span>
            </MParameter>
            <MParameter>
                <label>Gesammelte Pace</label>
                <span>{totalCollectedCoins}</span>
            </MParameter>
        </div>
    }

    function renderAvatarPhoto() {
        return <div>
            <h4 style={{marginBottom: "10px"}}>Avatar</h4>
            <WebManagedDatasetPanel dataset={avatarPhoto}
                                    loadingStatus={loadingAvatarPhotoStatus}
                                    gameTitle={gameTitle}/>
            <MJsonImage jsonString={avatarPhotoSrc}
                        style={{marginTop: "12px"}}/>
            {isLoadingAvatarPhoto && avatarPhoto == null && <MHint title={"Avatar laden ..."}/>}
        </div>
    }

    return <MPanel title={"Spielstand"} iconScr={"SlidariRush_Logo.svg"}>
        {renderSaveGame()}
        <hr style={{marginBottom: "24px"}}/>
        {renderAvatarPhoto()}
    </MPanel>;
}
