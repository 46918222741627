import {ChangeEvent, ChangeEventHandler, InputHTMLAttributes, useEffect, useState} from "react";
import "./MInputBase.css";
import "./MInput.css";

import {
    extractInputAttributesFromValidators,
    IMInputBaseProps,
    MInputValidatorWithMeta,
    MValidationUtilities
} from "./MValidationUtilities";
import {MParameterHint} from "./MParameterHint";

export interface IMInputProps extends InputHTMLAttributes<HTMLInputElement>, IMInputBaseProps {
    /**
     * On Change Callback
     */
    onChange?: ChangeEventHandler<HTMLInputElement> | undefined;
    validators?: MInputValidatorWithMeta[];
    name: string;
    labelContent?: any;
}

export function MInput({validators, value, onChange, name, labelContent, ...props}: IMInputProps) {
    const [validationError, setValidationValidationError] = useState<string | undefined>(undefined);

    useEffect(() => {
        MValidationUtilities.validateInput(String(value), setValidationValidationError, validators);
    }, []);

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        if (onChange != null) {
            onChange(e);
        }

        MValidationUtilities.validateInput(String(value), setValidationValidationError, validators);
    };

    const htmlAttributes = extractInputAttributesFromValidators(validators == null ? [] : validators);
    const combinedClassName = `m-input-base ${props.className ?? ''}`;

    return <>
        {props.type !== "checkbox" && labelContent != null && <label>{labelContent}</label>}
        <div className="m-input" style={props.type === "checkbox" ? {height: "22px"} : {}}>
            <input name={name}
                   className={combinedClassName}
                   {...props}
                   {...htmlAttributes}
                   value={value}
                   onChange={handleChange}/>
            {props.type === "checkbox" && labelContent != null && <label>{labelContent}</label>}
            <MParameterHint>{validationError}</MParameterHint>
        </div>
    </>;
}