import React from 'react';
import "./MTitle.css";

import "../../assets/images/User.svg"

import {IMComponentProps} from "./IMComponentProps";
import {IMTitledComponentProps} from "./IMTitledComponentProps";

interface IMTitleProps extends IMComponentProps<HTMLElement>, IMTitledComponentProps {
}

export function MTitle({title, iconScr, iconAlt, children, style, onClick}: IMTitleProps) {
    let titleIco: any;
    if (iconScr != null) {
        try {
            titleIco = require(`../../assets/images/${iconScr}`);
        } catch {
            console.log(`Icon ../../assets/images/${iconScr} not found!`);
            titleIco = null;
        }
    }

    return <div className="m-title" style={style} onClick={onClick}>
        {titleIco != null && <img src={titleIco} alt={iconAlt}/>}
        <h3>{title}</h3>
        {children}
    </div>;
}