import {MParameter} from "./core/MParameter";
import {MHint} from "./core/MHint";

import {MStatusStyle} from "./core/MStatusStyle";

export interface IWebManagedDatasetPanelProps {
    gameTitle?: string | null;
    dataset?: WebManagedDataset | null;
    loadingStatus?: number | null;
    expertViewActive?: boolean;
}

export class WebManagedDataset {
    /**
     * Kennung (Primärschlüssel) der Entität
     */
    id?: number;
    /**
     * Gruppe zu welcher der Datensatz gehört
     */
    groupId?: string;
    /**
     * Kennung des Datenbesitzers
     */
    ownerId?: string;
    /**
     * JSON Daten
     */
    jsonData?: any | null;
    /**
     * Zeitpunkt der Erzeugung des Datensatzes in der Datenbank
     */
    creation ?: any;
    /**
     * Zeitpunkt der letzten Veränderung des Datensatzes in der Datenbank
     */
    lastModification: any;
}

export function WebManagedDatasetPanel({
                                           dataset,
                                           gameTitle,
                                           loadingStatus,
                                           expertViewActive
                                       }: IWebManagedDatasetPanelProps) {
    if (expertViewActive == null) {
        expertViewActive = false;
    }

    function renderLoadingStatus() {
        if (loadingStatus == 401) {
            return <MHint title={"Fehlende Berechtigung!"}
                          description={"Zum Ausführen der Aktion fehlen Berechtigungen!"}
                          statusStyle={MStatusStyle.Warning}/>
        } else if (loadingStatus == 404) {
            return <MHint title={"Keine Daten gefunden!"}
                          description={`Aktuell existiert keine Daten für ${gameTitle}!`}
                          statusStyle={MStatusStyle.Default}/>
        } else if (loadingStatus !== 200) {
            return <MHint title={"Fehler beim Laden der Daten!"}
                          description={`Beim Laden der Daten ist ein Fehler aufgetreten HTTP ${loadingStatus}!`}
                          statusStyle={MStatusStyle.Warning}/>
        }

        return <></>;
    }

    if (loadingStatus !== 200) {
        return renderLoadingStatus();
    } else {
        if (dataset == null) {
            return <></>;
        }

        return <div>
            {expertViewActive &&
                <>
                    <MParameter>
                        <label>Entity ID</label>
                        <span>{dataset.id}</span>
                    </MParameter>
                    <MParameter>
                        <label>Gruppenkennung</label>
                        <span>{dataset.groupId}</span>
                    </MParameter>
                    <MParameter>
                        <label>Besitzerkennung</label>
                        <span>{dataset.ownerId}</span>
                    </MParameter>
                    <MParameter>
                        <label>Erzeugt</label>
                        <span>{dataset.creation}</span>
                    </MParameter>
                </>}
            <MParameter>
                <label>Zuletzt geändert</label>
                <span>{dataset.lastModification}</span>
            </MParameter>
        </div>
    }
}
