import {MApiResponse} from "./MApiResponse";

export class MApiAdapter {
    private static async fetchApi<T>(url: string, method: string, jwt: string, body: any): Promise<MApiResponse<T>> {
        try {
            const headers: { [key: string]: string } = {
                'Content-Type': 'application/json',
            };

            if (jwt) {
                headers['Authorization'] = `Bearer ${jwt}`;
            }

            const options: RequestInit = {
                method: method,
                headers: headers
            };

            if (body !== null) {
                options.body = JSON.stringify(body);
            }

            const response = await fetch(url, options);
            const status = response.status;

            if (response.ok) {
                let data: T | null = null;
                const contentType = response.headers.get('Content-Type');
                if (contentType != null && contentType.includes('application/json')) {
                    data = await response.json() as T;
                }

                return new MApiResponse<T>(status, data);
            } else {
                const errorData = await response.text();
                return new MApiResponse<T>(status, null, errorData);
            }
        } catch (error) {
            return new MApiResponse<T>(-1, null, (error as Error).message);
        }
    }

    public static async fetchFromApi<T>(url: string, jwt: string = "", body: any = null): Promise<MApiResponse<T>> {
        return await this.fetchApi(url, "GET", jwt, body);
    }

    public static async postToApi<T>(url: string, jwt: string = "", body: any = null): Promise<MApiResponse<T>> {
        return await this.fetchApi(url, "POST", jwt, body);
    }

    public static async putToApi<T>(url: string, jwt: string = "", body: any = null): Promise<MApiResponse<T>> {
        return await this.fetchApi(url, "PUT", jwt, body);
    }

    public static async patchAtApi<T>(url: string, jwt: string = "", body: any = null): Promise<MApiResponse<T>> {
        return await this.fetchApi(url, "PATCH", jwt, body);
    }
}