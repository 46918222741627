import "./MHint.css";

import {IMComponentProps} from "./IMComponentProps";
import {MStatusStyle} from "./MStatusStyle";
import {ReactNode} from "react";

interface IMHintProps extends IMComponentProps<HTMLElement> {
    title?: ReactNode | null;
    description?: ReactNode | null;
    statusStyle?: MStatusStyle;
    renderBorder?: boolean;
}

export function MHint({title, description, statusStyle, renderBorder, children, style, onClick}: IMHintProps) {
    if (statusStyle == null) {
        statusStyle = MStatusStyle.Default;
    }

    if (renderBorder == null) {
        renderBorder = true;
    }

    function getClassName() {
        let className = "m-hint";
        if (renderBorder) {
            className += " bordered";
        }

        switch (statusStyle) {
            case MStatusStyle.Default:
                className += " default";
                break;
            case MStatusStyle.Nice:
                className += " nice";
                break;
            case MStatusStyle.Warning:
                className += " warning";
                break;
            case MStatusStyle.Error:
                className += " error";
                break;
        }

        return className;
    }

    return <div className={getClassName()} style={style} onClick={onClick}>
        {title != null && title != "" && <div><span>{title}</span></div>}
        {description != null && description != "" && <div><span>{description}</span></div>}
        {children}
    </div>;
}