import React, {useEffect, useRef, useState} from "react";
import "./MFirebaseUserManagementPanel.css";

import {MParameter} from "../core/MParameter";
import {MButton} from "../core/MButton";
import {MPanel} from "../core/MPanel";
import {useNavigate} from "react-router-dom";
import {MHint} from "../core/MHint";
import {usePushMessage} from "../core/MPushMessageProvider";
import {MButtonBar} from "../core/MButtonBar";
import {MInput} from "../core/MInput";
import {MInputForm} from "../core/MInputForm";
import MCaptcha from "../core/MCaptcha";

import {
    browserLocalPersistence,
    createUserWithEmailAndPassword,
    sendEmailVerification,
    onAuthStateChanged,
    sendPasswordResetEmail,
    signInWithEmailAndPassword,
    signOut
} from 'firebase/auth';
import {IMComponentProps} from "../core/IMComponentProps";
import {MUser} from "../../types/core/MUser";
import {MStatusStyle} from "../core/MStatusStyle";
import {SlidariDataApi} from "../../api/SlidariDataApi";
import {MFirebaseUser} from "../../types/firebase/MFirebaseUser";

import {auth} from '../../Firebase';
import {MInputValidationRules} from "../core/MValidationUtilities";
import {MParameterHint} from "../core/MParameterHint";
import {MLink} from "../core/MLink";

export enum UserManagementAction {
    Register = 1,
    Login = 2,
    DeleteAccount = 3,
    EditAccount = 4,
    ResetPassword = 5,
    ShowUserInformation = 10
}

interface IMFirebaseUserManagementPanelProps extends IMComponentProps<HTMLElement> {
}

export function MFirebaseUserManagementPanel({}: IMFirebaseUserManagementPanelProps) {
    const registerUserFormValidationRef = useRef<any>(null);
    const loginUserFormValidationRef = useRef<any>(null);
    const editUserFormValidationRef = useRef<any>(null);

    const [firebaseUser, setFirebaseUser] = useState<MFirebaseUser | null>();
    const [user, setUser] = useState<MUser | null>();
    const [managementAction, setManagementAction] = useState<UserManagementAction>();
    const [registerCaptchaExpected, setRegisterCaptchaExpected] = useState<boolean>(false);
    const [email, setEmail] = useState<string>("");
    const [userName, setUserName] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [password2, setPassword2] = useState<string>("");
    const [isAccountPrivate, setIsAccountPrivate] = useState<boolean>();
    const [isPrivatePolicyAccepted, setIsPrivatePolicyAccepted] = useState<boolean>(false);
    const [lastApiCallErrorText, setLastApiCallErrorText] = useState<string | null>(null);
    const [lastApiCallErrorHelp, setLastApiCallErrorHelp] = useState<string | null>(null);
    const [isWaitingForServer, setIsWaitingForServer] = useState<boolean>(false);

    useEffect(() => {
        onAuthStateChanged(auth, (user) => {
            if (user) {
                setFirebaseUser(new MFirebaseUser(user.uid, user.email, user.emailVerified));
                loadUser().then(r => {
                    setManagementAction(UserManagementAction.ShowUserInformation);
                });
            } else {
                setFirebaseUser(null);
                setManagementAction(UserManagementAction.Login);
                setUser(null);
            }
        });
    }, [])

    const navigate = useNavigate();
    const pushMessage = usePushMessage();

    /**
     * Benutzer Anmelden
     */
    async function registerUser() {
        try {
            setIsWaitingForServer(true);

            await auth.setPersistence(browserLocalPersistence);
            await createUserWithEmailAndPassword(auth, email, password);
            await sendUserEmailVerificationMail();
            await checkUserProfile();
            if (auth.currentUser != null) {
                await updateUser();
                await loadUser();
            }

            setLastApiCallResult(null);
            pushMessage.push("Profil erfolgreich erstellt!", undefined, MStatusStyle.Nice, 3000);
        } catch (error) {
            setLastApiCallResult(error);
        } finally {
            setIsWaitingForServer(false);
        }
    }

    /**
     * E-Mail Verifizierung senden
     */
    async function sendUserEmailVerificationMail() {
        if (auth.currentUser == null) {
            return;
        }

        try {
            await sendEmailVerification(auth.currentUser);

            setLastApiCallResult(null);
            pushMessage.push("Verifizierungs-E-Mail gesendet!",
                "Prüfe dein Postfach und verwende den Bestätigungslink in der E-Mail zur Verifizierung.",
                MStatusStyle.Nice, 3000);
        } catch (error) {
            setLastApiCallResult(error);
        }
    }

    /**
     * Benutzer Anmelden
     */
    async function loginUser() {
        try {
            setIsWaitingForServer(true);

            await auth.setPersistence(browserLocalPersistence);
            await signInWithEmailAndPassword(auth, email, password);
            await checkUserProfile();

            setLastApiCallErrorText(null);
            pushMessage.push("Anmeldung erfolgreich!", undefined, MStatusStyle.Nice, 3000);
        } catch (error) {
            setLastApiCallResult(error);
        } finally {
            setIsWaitingForServer(false);
            navigate("/manage-profile");
        }
    }

    /**
     * Hier wird geprüft, ob die Benutzerprofilerweiterung auf dem Slidari Server existiert
     */
    async function checkUserProfile() {
        if (auth.currentUser == null) {
            return;
        }

        const jwt = await auth.currentUser.getIdToken(true);

        const userExtensionsResponse = await SlidariDataApi.fetchUser<MUser>(auth.currentUser.uid, jwt);
        setUser(userExtensionsResponse.data);
        if (userExtensionsResponse.status === 404) {
            const userExtensionsCreatedResponse = await SlidariDataApi.createUser<MUser>(new MUser(auth.currentUser.uid, ""), jwt);
            if (userExtensionsCreatedResponse.status !== 201) {
                pushMessage.push("Benutzerprofil vervollständigen!", "Beim Vervollständigen des Benutzerprofils ist ein Fehler aufgetreten!", MStatusStyle.Error);
            } else {
                setUser(userExtensionsCreatedResponse.data);
            }
        } else if (userExtensionsResponse.status !== 200) {
            setTimeout(() => {
                pushMessage.push("Benutzerprofil unvollständig!", "Dein Benutzerprofil ist unvollständig, bitte melde dich erneut an um das Problem zu beheben.", MStatusStyle.Warning);
            }, 1000);
        }
    }

    /**
     * Benutzer vom Slidari Server laden
     */
    async function loadUser() {
        if (auth.currentUser == null) {
            setUser(null);
            return;
        }

        try {
            const jwt = await auth.currentUser.getIdToken(true);
            const userResponse = await SlidariDataApi.fetchUser<MUser>(auth.currentUser.uid, jwt);
            if (userResponse.status === 200) {
                setUser(userResponse.data);
                setIsAccountPrivate(userResponse.data?.isAccountPrivate);
            } else {
                setUser(null);
                setIsAccountPrivate(false);
            }
        } catch (error) {
            setLastApiCallResult(error);
        }
    }

    /**
     * Benutzer abmelden
     */
    async function logoutUser() {
        try {
            setIsWaitingForServer(true);
            await signOut(auth);

            setLastApiCallErrorText(null);
            pushMessage.push("Abmeldung erfolgreich!", "Bis zum nächsten mal.", MStatusStyle.Nice, 3000);
        } catch (error) {
            setLastApiCallResult(error);
            pushMessage.push("Fehler beim Abmelden!", undefined, MStatusStyle.Warning);
        } finally {
            setIsWaitingForServer(false);
        }
    }

    /**
     * Benutzerprofil aktualisieren
     */
    async function updateUser() {
        if (auth.currentUser == null) {
            return false;
        }

        try {
            setIsWaitingForServer(true);
            setLastApiCallErrorText(null);

            const jwt = await auth.currentUser.getIdToken(true);
            const updatedUser = new MUser(auth.currentUser.uid, userName);
            updatedUser.isPrivatePolicyAccepted = isPrivatePolicyAccepted;
            updatedUser.isAccountPrivate = isAccountPrivate;
            const userUpdatedResponse = await SlidariDataApi.updateUser<MUser>(updatedUser, jwt);
            if (userUpdatedResponse.status !== 200) {
                pushMessage.push("Benutzerprofil vervollständigen!", "Beim Ändern des Benutzerprofils ist ein Fehler aufgetreten!", MStatusStyle.Error);
                return false;
            }

            setUser(userUpdatedResponse.data);
            pushMessage.push("Benutzerprofil geändert!", undefined, MStatusStyle.Nice, 3000);
            return true;
        } catch (error) {
            setLastApiCallResult(error);
            pushMessage.push("Fehler beim Ändern des Benutzerprofils!", undefined, MStatusStyle.Warning);
        } finally {
            setIsWaitingForServer(false);
        }

        return false;
    }

    /**
     * Benutzer Passwort zurücksetzen
     */
    async function resetUserPassword(): Promise<boolean> {
        if (email == null && firebaseUser == null) {
            return false;
        }

        try {
            let useEmail: string | undefined | null = email;
            if (useEmail == null || useEmail === "") {
                useEmail = firebaseUser?.mailAddress;
            }

            if (useEmail == null) {
                return false;
            }

            setIsWaitingForServer(true);
            await sendPasswordResetEmail(auth, useEmail);

            setLastApiCallErrorText(null);
            pushMessage.push("Passwort zurücksetzen!", "Mit der E-Mail in deinem Postfach, kannst du dein Passwort ändern.", MStatusStyle.Nice);
            return true;
        } catch (error) {
            setLastApiCallResult(error);
            pushMessage.push("Fehler beim Zurücksetzen des Passworts!", undefined, MStatusStyle.Warning);
        } finally {
            setIsWaitingForServer(false);
        }

        return false;
    }

    /**
     * Benutzer-Account löschen
     */
    async function deleteUserAccount() {
        if (auth.currentUser == null) {
            return;
        }

        try {
            const jwt = await auth.currentUser.getIdToken(true);

            const markUserDeletedResponse = await SlidariDataApi.markUserDeleted(auth.currentUser.uid, jwt)
            if (markUserDeletedResponse.status !== 200 && markUserDeletedResponse.status !== 404) {
                pushMessage.push("Fehler beim Löschen des Profils!", "Das Löschen des Profils ist fehlgeschlagen!", MStatusStyle.Warning);
                return;
            }

            setIsWaitingForServer(true);
            await auth.currentUser.delete();

            setLastApiCallErrorText(null);
            pushMessage.push("Profil löschen!", "Dein Profil wurde endgültig gelöscht.", MStatusStyle.Warning);
        } catch (error) {
            setLastApiCallResult(error);
            pushMessage.push("Fehler beim Löschen des Profils!", undefined, MStatusStyle.Warning);
        } finally {
            setIsWaitingForServer(false);
        }
    }

    function setLastApiCallResult(error: any) {
        if (error == null) {
            return;
        }

        let errorText = error.message;
        let helpText = "";
        if (error.code === "auth/too-many-requests") {
            errorText = "Zu viele Versuche!";
            helpText = "Die Maximalanzahl an Versuchen, für diese Aktion, wurde erreicht. Warte einen Moment, bis du die Aktion erneut ausführst."
        } else if (error.code === "auth/invalid-email") {
            errorText = "Unzulässige E-Mail Adresse!";
        } else if (error.code === "auth/missing-password") {
            errorText = "Passwort unzulässig!";
            helpText = "Gebe bitte ein zulässiges Passwort ein."
        } else if (error.code === "auth/invalid-credential") {
            errorText = "Anmeldedaten unzulässig!";
            helpText = "Überprüfe, ob E-Mail Adresse und Passwort stimmen!"
        } else if (error.code === "auth/weak-password") {
            errorText = "Passwort zu schwach!";
            helpText = "Bitte wähle ein stärkeres Passwort."
        } else if (error.code === "auth/email-already-in-use") {
            errorText = "E-Mail Adresse nicht verfügbar!";
            helpText = "Die eingegebene E-Mail Adresse wird bereits in einem anderen Account verwendet."
        } else if (error.code === "auth/requires-recent-loginUser") {
            errorText = "Erneute Anmeldung erforderlich!";
            helpText = "Zum Ausführen dieser Aktion ist eine erneute Anmeldung erforderlich."
        } else if (error.code === "auth/firebaseUser-disabled") {
            errorText = "Profil deaktiviert!";
            helpText = "Dein Profil wurde vorübergehend deaktiviert. Wende dich an einen Administrator."
        } else if (error.code === "auth/network-request-failed") {
            errorText = "Kein Netzwerk!";
            helpText = "Prüfe, ob du aktuell eine Verbindung zum Internet hast."
        }

        setLastApiCallErrorText(errorText);
        setLastApiCallErrorHelp(helpText);
        console.log(error.message);
    }

    const validateRegisterUserForm = () => {
        if (registerUserFormValidationRef.current && typeof registerUserFormValidationRef.current.triggerValidation === 'function') {
            registerUserFormValidationRef.current.triggerValidation();
        }
    };

    const validateLoginUserForm = () => {
        if (loginUserFormValidationRef.current && typeof loginUserFormValidationRef.current.triggerValidation === 'function') {
            loginUserFormValidationRef.current.triggerValidation();
        }
    };

    const validateEditUserForm = () => {
        if (editUserFormValidationRef.current && typeof editUserFormValidationRef.current.triggerValidation === 'function') {
            editUserFormValidationRef.current.triggerValidation();
        }
    };

    function clearFields() {
        setEmail("");
        setUserName("");
        setPassword("");
        setPassword2("");
    }

    function clearErrors() {
        setLastApiCallErrorText(null);
        setLastApiCallErrorHelp(null);
    }

    function onLoginNowClicked() {
        const emailTmp = email;
        clearFields();
        clearErrors();
        setEmail(emailTmp);
        setManagementAction(UserManagementAction.Login);
    }

    function onRegisterNowClicked() {
        const emailTmp = email;
        clearFields();
        clearErrors();
        setEmail(emailTmp);
        setManagementAction(UserManagementAction.Register);
    }

    async function onRegisterClicked() {
        clearErrors();
        if (isPrivatePolicyAccepted) {
            validateRegisterUserForm();
        }
    }

    const handleRegisterUserFormValidation = async (isValid: boolean, formData: { [key: string]: string }) => {
        if (isValid) {
            setRegisterCaptchaExpected(true);
        }
    };

    async function onSendEmailVerificationMailClicked() {
        clearErrors();
        await sendUserEmailVerificationMail();
    }

    async function onLoginClicked() {
        clearErrors();
        validateLoginUserForm();
    }

    const handleLoginUserFormValidation = async (isValid: boolean, formData: { [key: string]: string }) => {
        if (isValid) {
            await loginUser();
        }
    };

    async function onLogoutClicked() {
        clearErrors();
        await logoutUser();
    }

    function onEditAccountClicked() {
        if (user == null) {
            pushMessage.push("Bearbeitung nicht möglich!", "Aufgrund eines Problems ist die Bearbeitung aktuell nicht möglich!", MStatusStyle.Warning);
            return;
        }

        clearFields();
        clearErrors();
        if (user.displayName != null) {
            setUserName(user.displayName);
        }

        setManagementAction(UserManagementAction.EditAccount);
    }

    async function onSaveChangesClicked() {
        clearErrors();
        validateEditUserForm();
    }

    const handleEditUserFormValidation = async (isValid: boolean, formData: { [key: string]: string }) => {
        if (isValid) {
            const done = await updateUser();
            if (done) {
                clearFields();
                clearErrors();
                setManagementAction(UserManagementAction.ShowUserInformation);
                await loadUser();
            }
        }
    };

    function onAbortEditClicked() {
        clearFields();
        clearErrors();
        setManagementAction(UserManagementAction.ShowUserInformation);
    }

    function onStartResetPasswordClicked() {
        setManagementAction(UserManagementAction.ResetPassword);
    }

    async function onResetPasswordClicked() {
        clearErrors();
        const done = await resetUserPassword();
        if (done) {
            clearFields();
            clearErrors();
            if (firebaseUser != null) {
                setManagementAction(UserManagementAction.ShowUserInformation);
            } else {
                setManagementAction(UserManagementAction.Login);
            }
        }
    }

    function onAbortResetPasswordClicked() {
        clearFields();
        clearErrors();
        setManagementAction(UserManagementAction.Login);
    }

    function onDeleteAccountClicked() {
        clearFields();
        clearErrors();
        setManagementAction(UserManagementAction.DeleteAccount);
    }

    async function onDeleteAccountPermanentClicked() {
        await deleteUserAccount();
    }

    function onAbortDeleteAccountClicked() {
        clearErrors();
        setManagementAction(UserManagementAction.ShowUserInformation);
    }

    function renderRegister() {
        if (registerCaptchaExpected) {
            return <></>;
        }

        return <MInputForm style={{display: "flex", flexDirection: "column"}}
                           ref={registerUserFormValidationRef}
                           onValidate={handleRegisterUserFormValidation}>
            <MParameter key={"emailRegister"}>
                <MInput name={"emailRegister"}
                        type="email"
                        labelContent="E-Mail Adresse"
                        validators={[MInputValidationRules.email]}
                        placeholder={"E-Mail Adresse ..."}
                        value={email}
                        onChange={(value) => {
                            setEmail(value.target.value);
                        }}/>
            </MParameter>
            <MParameter key={"userNameRegister"}>
                <MInput name={"userNameRegister"}
                        type="text"
                        labelContent="Benutzername"
                        placeholder={"Benutzername ..."}
                        validators={[MInputValidationRules.required, MInputValidationRules.minLength(4)]}
                        value={userName}
                        onChange={(value) => {
                            setUserName(value.target.value);
                        }}/>
            </MParameter>
            <MParameter key={"password1Register"}>
                <MInput name={"password1Register"}
                        type="password"
                        labelContent="Passwort"
                        autoComplete="one-time-code"
                        placeholder={"Passwort ..."}
                        validators={[MInputValidationRules.required, MInputValidationRules.minLength(6)]}
                        value={password}
                        onChange={(value) => {
                            setPassword(value.target.value);
                        }}/>
            </MParameter>
            <MParameter key={"password2Register"}>
                <MInput name={"password2Register"}
                        type="password"
                        labelContent="Passwort Wiederholung"
                        autoComplete="one-time-code"
                        placeholder={"Passwort Wiederholung ..."}
                        validators={[MInputValidationRules.required, MInputValidationRules.minLength(6)]}
                        value={password2}
                        onChange={(value) => {
                            setPassword2(value.target.value);
                        }}/>
            </MParameter>
            <MParameter key={"isPrivatePolicyAcceptedRegister"}>
                <MInput name={"isPrivatePolicyAcceptedRegister"}
                        type="checkbox"
                        labelContent={<div style={{whiteSpace: "collapse"}}>Ich habe die&#160;
                            <a href="/privacy-policy/"
                               target="_blank"
                               rel="noopener noreferrer">Datenschutzerklärung</a>&#160;gelesen und bin damit
                            einverstanden.</div>}
                        checked={isPrivatePolicyAccepted}
                        onChange={value => {
                            setIsPrivatePolicyAccepted(Boolean(value.target.checked));
                        }}/>
                {!isPrivatePolicyAccepted &&
                    <MParameterHint>Datenschutzerklärung muss akzeptiert werden!</MParameterHint>}
            </MParameter>
            <MButton onClick={onRegisterClicked} disabled={isWaitingForServer}>Registrieren</MButton>
            <div className="userManageLinks">
                <MLink onClick={onLoginNowClicked}>Bereits registriert</MLink>
            </div>
        </MInputForm>
    }

    function renderRegisterCaptcha() {
        if (registerCaptchaExpected) {
            return <>
                <MCaptcha verified={registerCaptchaVerified}/>
                <MButton style={{marginTop: "22px"}} onClick={() => {
                    setRegisterCaptchaExpected(false);
                }}>Zurück</MButton>
            </>;
        }

        return <></>;
    }

    async function registerCaptchaVerified(status: boolean) {
        if (registerCaptchaExpected && status) {
            setRegisterCaptchaExpected(false);
            await registerUser();
        }
    }

    function renderLogin() {
        return <MInputForm style={{display: "flex", flexDirection: "column"}}
                           ref={loginUserFormValidationRef}
                           onValidate={handleLoginUserFormValidation}>
            <MParameter key={"emailLogin"}>
                <MInput name={"emailLogin"}
                        type="email"
                        labelContent="E-Mail Adresse"
                        placeholder={"E-Mail Adresse ..."}
                        validators={[MInputValidationRules.email, MInputValidationRules.minLength(8)]}
                        value={email}
                        onChange={(value) => {
                            setEmail(value.target.value);
                        }}/>
            </MParameter>
            <MParameter key={"passwordLogin"}>
                <MInput name={"passwordLogin"}
                        type="password"
                        labelContent="Passwort"
                        placeholder={"Passwort ..."}
                        validators={[MInputValidationRules.required]}
                        value={password}
                        onChange={(value) => {
                            setPassword(value.target.value);
                        }}/>
            </MParameter>
            <MButton onClick={onLoginClicked} disabled={isWaitingForServer}>Anmelden</MButton>
            <div className="userManageLinks">
                <MLink onClick={onStartResetPasswordClicked}>Passwort vergessen</MLink>
                <span>|</span>
                <MLink onClick={onRegisterNowClicked}>Jetzt Registrieren</MLink>
            </div>
        </MInputForm>;
    }

    function renderEditAccount() {
        return <MInputForm style={{display: "flex", flexDirection: "column"}}
                           ref={editUserFormValidationRef}
                           onValidate={handleEditUserFormValidation}>
            <MParameter key={"userNameEdit"}>
                <MInput name={"userNameEdit"}
                        type="text"
                        labelContent="Benutzername"
                        placeholder={"Benutzername ..."}
                        value={userName}
                        onChange={(value) => {
                            setUserName(value.target.value);
                        }}/>
            </MParameter>
            <MParameter key={"isAccountPrivateEdit"}>
                <MInput name={"isAccountPrivateEdit"}
                        type="checkbox"
                        labelContent="Profil privat"
                        checked={isAccountPrivate}
                        onChange={(value) => {
                            setIsAccountPrivate(Boolean(value.target.checked));
                        }}/>
            </MParameter>
            {isAccountPrivate && <MHint title="Privates Profil!"
                                        description="Inhalte privater Profile tauchen nicht in Bestenlisten und anderen öffentlichen Bereichen auf."
                                        style={{marginBottom: "12px"}}/>}
            <MButtonBar>
                <MButton onClick={onSaveChangesClicked} disabled={isWaitingForServer}>Änderungen übernehmen</MButton>
                <MButton onClick={onAbortEditClicked} disabled={isWaitingForServer}>Abbrechen</MButton>
            </MButtonBar>
        </MInputForm>
    }

    function renderResetPassword() {
        return <MInputForm style={{display: "flex", flexDirection: "column"}}
                           ref={editUserFormValidationRef}
                           onValidate={handleEditUserFormValidation}>
            <MParameter key={"emailResetPassword"}>
                <MInput name={"emailResetPassword"}
                        type="email"
                        labelContent="E-Mail Adresse"
                        placeholder={"E-Mail Adresse ..."}
                        value={email}
                        onChange={(value) => {
                            setEmail(value.target.value);
                        }}/>
            </MParameter>
            <MButtonBar>
                <MButton onClick={onResetPasswordClicked} disabled={isWaitingForServer || email == ""}>Passwort
                    zurücksetzen</MButton>
                <MButton onClick={onAbortResetPasswordClicked} disabled={isWaitingForServer}>Abbrechen</MButton>
            </MButtonBar>
        </MInputForm>
    }

    function renderShowUserInformation() {
        return <div>
            <MParameter>
                <label>Benutzername</label>
                <p>{user?.displayName == null ? "-" : user?.displayName}</p>
            </MParameter>
            <MParameter>
                <label>E-Mail Adresse</label>
                <p>{firebaseUser?.mailAddress == null ? "-" : firebaseUser?.mailAddress}</p>
            </MParameter>
            <MParameter>
                <label>Profil Sichtbarkeit</label>
                <p>{user?.isAccountPrivate ? "Privat" : "Öffentlich"}</p>
            </MParameter>
            <MParameter>
                <label>Benutzerkennung</label>
                <p>{firebaseUser?.userId == null ? "-" : firebaseUser?.userId}</p>
            </MParameter>
            <MParameter>
                <label>Benutzergruppen</label>
                <p>{user?.userRolesChain == null ? "-" : user?.userRolesChain}</p>
            </MParameter>
            {!firebaseUser?.mailVerified && <div>
                <MHint title={"Profil nicht verifiziert!"}
                       description={"Dein Profil ist noch nicht bestätigt. Prüfe deine E-Mails."}
                       style={{marginBottom: "0"}}
                       statusStyle={MStatusStyle.Warning}>
                </MHint>
                <MButtonBar style={{marginBottom: "22px", borderTop: "none"}}>
                    <MButton onClick={onSendEmailVerificationMailClicked}
                             disabled={isWaitingForServer}
                             style={{borderTop: "none", borderTopLeftRadius: "0", borderTopRightRadius: "0"}}>
                        Verifizierungs-Mail erneut zusenden
                    </MButton>
                </MButtonBar>
            </div>}
            <MButtonBar>
                <MButton onClick={onLogoutClicked} disabled={isWaitingForServer}>Abmelden</MButton>
                <MButton onClick={onEditAccountClicked} disabled={isWaitingForServer}>Bearbeiten</MButton>
                <MButton onClick={onResetPasswordClicked} disabled={isWaitingForServer}>Password zurücksetzen</MButton>
            </MButtonBar>
            <MButtonBar>
                <MButton onClick={onDeleteAccountClicked}>Profil löschen</MButton>
            </MButtonBar>
        </div>
    }

    function renderDeleteAccount() {
        return <div style={{display: "flex", flexDirection: "column"}}>
            <MHint title="Wichtiger Hinweis!"
                   description="Dieser Vorgang kann nicht rückgängig gemacht werden! Sämtliche Spieldaten werden entgültig aus dem System gelöscht."
                   statusStyle={MStatusStyle.Warning}/>
            <MButtonBar>
                <MButton style={{marginTop: "12px"}} onClick={onDeleteAccountPermanentClicked}>Profil endgültig
                    löschen</MButton>
                <MButton style={{marginTop: "12px"}} onClick={onAbortDeleteAccountClicked}>Abbrechen</MButton>
            </MButtonBar>
        </div>
    }

    function renderActions() {
        switch (managementAction) {
            case UserManagementAction.Register:
                return <>
                    {renderRegister()}
                    {renderRegisterCaptcha()}
                </>
            case UserManagementAction.Login:
                return renderLogin();
            case UserManagementAction.DeleteAccount:
                return renderDeleteAccount();
            case UserManagementAction.EditAccount:
                return renderEditAccount();
            case UserManagementAction.ResetPassword:
                return renderResetPassword();
            case UserManagementAction.ShowUserInformation:
                return renderShowUserInformation();
        }

        return <></>;
    }

    function renderLastActionError() {
        if (lastApiCallErrorText != null) {
            return <MHint title={lastApiCallErrorText}
                          style={{marginTop: "12px"}}
                          statusStyle={MStatusStyle.Warning}
                          renderBorder={true}
                          description={lastApiCallErrorHelp}/>
        }

        return <></>;
    }

    function getTitle() {
        switch (managementAction) {
            case UserManagementAction.Register:
                return "Registrieren";
            case UserManagementAction.Login:
                return "Anmelden";
            case UserManagementAction.DeleteAccount:
                return "Benutzerprofil löschen";
            case UserManagementAction.ResetPassword:
                return "Benutzerprofil Passwort zurücksetzen";
            case UserManagementAction.ShowUserInformation:
                return "Benutzerprofil";
        }

        return "Benutzerprofil";
    }

    return <MPanel title={getTitle()}
                   iconScr={firebaseUser != null ? "User_Backgrounded.svg" : "User.svg"}
                   className="m-firebase-user-management-panel">
        {renderActions()}
        {renderLastActionError()}
    </MPanel>
}