import React, {ChangeEvent, ReactElement, ReactNode, SelectHTMLAttributes} from "react";
import "./MInputBase.css";
import "./MSelect.css";

import {IMInteractiveComponentProps} from "./IMInteractiveComponentProps";
import {IMInputBaseProps, MInputValidatorWithMeta} from "./MValidationUtilities";

interface IMSelectProps extends Omit<SelectHTMLAttributes<HTMLSelectElement>, 'value' | 'onChange'>, IMInteractiveComponentProps<HTMLSelectElement>, IMInputBaseProps {
    value?: number;
    onChange?: (value: number) => void;
    children: ReactNode;
    validators?: MInputValidatorWithMeta[];
    name: string;
    labelContent?: any;
}

export function MSelect(props: IMSelectProps) {
    const {value, onChange, children, ...restProps} = props;

    const handleChange = (event: ChangeEvent<HTMLSelectElement>) => {
        const index = event.target.selectedIndex;
        if (onChange) {
            onChange(index);
        }
    };

    /**
     * Überschreibt die Werte aller Select Optionen
     */
    const childrenWithIndexedValues = React.Children.map(children, (child, index) => {
        if (React.isValidElement(child) && child.type === 'option') {
            return React.cloneElement(child as ReactElement, {value: index});
        }

        return child;
    });

    const combinedClassName = `m-select m-input-base ${props.className ?? ''}`;

    return <select className={combinedClassName}
                   {...restProps}
                   value={value !== undefined ? value : undefined}
                   onChange={handleChange}>
        {childrenWithIndexedValues}
    </select>;
}